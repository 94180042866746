import skills from '../constants/skills';
import projects from '../constants/projects';
function App() {
  return (
    <div>
      <div className="grid-container">

        <div className="tout">
          <h3>Selected Skills:</h3>
          <ul className="skills">
            <li>React: 8 years</li>
            <li>React Native: 8 years</li>
            <li>Javascript: 10 years</li>
            <li>Node: 5 years</li>
            <li>HTML: 25 years</li>
            <li>CSS: 20 years</li>
            <li>SQL: 15 years</li>
            <li>Wordpress: 20 years</li>
            <li>Adobe: 25 years</li>
            <li>REST APIs: 5 years</li>
            <li>C#: 6 years</li>
            <li>PHP: 15 years</li>
          </ul>
      </div>
        {
          projects.map((item, index) => {
            return (
              <div className="projects">

                <h4>{item.name}</h4>
                <p><span className="bold">Skills: </span>
                  {
                    projects[index].skills.map((i, idx) => {
                      let skill = i + ", ";
                      if (idx >= projects[index].skills.length - 1) {
                        skill = skill.slice(0, -2)
                      }
                      return (
                         skill 
                      )
                    })
                  }</p>
                <ul>
                  {
                projects[index].spiel.map((i, idx) => {
                  return(
                    <li>{i}</li>
                  )
                })
                  }
                </ul>
                <p>{projects[index].url === "In progress" ? "In progress" : <a href={projects[index].url}>LINK</a>}</p>
              </div>
            )
          })
        }
          </div>
          {/* <div className="lead-holder" style={{marginTop: "2rem"}}>
      <p className="lead">Full list of skills:</p>
      <ul className="skills-container">
          {
            skills.map((item, index) => {
              return (
                <li key={index}>{item}</li>
              )
            })
          }
        </ul>
      </div> */}
    </div>
  );
}

export default App;
