import { useState, useEffect } from 'react';
import axios from 'axios';
let url = "http://localhost:3000/"
url = "https://matthewgruman.com/"
function App() {

  const [blogs, setBlogs] = useState(null);

  useEffect(() => {
    axios.get('https://wordpress.matthewgruman.com/wp-json/wp/v2/posts')
    .then(results => {
      setBlogs(results.data)
      console.log(results.data)
    })
    .catch(err => {
      console.log(err)
    })
  },[])
  return (
    <article>
      {
        !blogs ? 
        <p>Problems reading from Wordpress. Please refresh.</p>
        :
        <ul className="blog-list">
          {
            blogs.map((item) => {
              return(
                <li key={item.id}>
                  <h2><a href={url + "blog/" + item.id}>{item.title.rendered}</a></h2>
                  <div dangerouslySetInnerHTML={{ __html: item.content.rendered }} />
                </li>
              )
            })
          }
        </ul>
      }
    </article>
  );
}

export default App;
