import apis from '../constants/apis';

function App() {

  return (
    <article>
      <h2>APIs</h2>
      <div className="apis">
        {
          apis.map((item, index) => {
            return(
              <div className="api">
                <h3>{item.name}</h3>
                <p className="italic">{item.description}</p>
                {
                  apis[index].urls.map((i, idx) => {
                    return (
                      <>
                      <p>{i.description}</p>
                      <input type="text" value={i.url} />
                      </>
                    )
                  })
                }
                <p><a href={item.github}>{item.github}</a></p>
                </div>
            )
          })
        }
      </div>
    </article>
  );
}

export default App;
