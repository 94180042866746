export default function App() {

  return(
    <article>
    <h2>2D Pool in Unity</h2>
    <p className="date">Last updated 3/6/2023</p>
     <p>This is a very simple tutorial on how to make a pool/billiards/snooker game. You can use it as a starting off point to make a more complex game and learn about 2D Colliders and Rigidbodys.</p>



<h3>Getting Started</h3>



<p>The first step is to watch "DRAG &amp; SHOOT in Unity" from Muddy Wolf Games and make the project. We are going to use that exact project and add pool elements on top of it.</p>
<iframe width="560" height="315" src="https://www.youtube.com/embed/Tsha7rp58LI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" className="youtube"  allowfullscreen></iframe>
<h2>Setting Up</h2>



<p>Now that you have the drag-and-drop system set up, we can add some pool.</p>



<p>The first thing to do is delete the platform from the tutorial project. To make the table download this image and drag it into your assets folder. Then drag it onto the scene. If you want to make your own, pool tables are typically a 2:1 ratio. Resize as needed to fit your screen size.</p>
<div style={{backgroundColor: '#ddd', padding: '1rem', marginBottom: '2rem'}}>
<img src={require('../../img/poolTable-1568x784.png')} className="full-img" />
</div>



<p>Click on the image in the Hierarchy and add a Rigidbody 2D a components. Set the Body Type to Static. This will let it collide with other objects, but never move.</p>

<img src={require('../../img/Screen-Shot-2021-04-22-at-9.12.56-PM.png')} className="half-img" />

<p>Next we're going to make a Physic Material. Right-click in your assets folder and create a new Physic Material.</p>


<img src={require('../../img/Screen-Shot-2021-04-22-at-9.16.06-PM-768x907.png')} className="half-img" />


<p>Select the new Material and change the Bounce to 0.8. This lets the balls bounce off the walls. If you want to add more bounce, just increase be Bounce value! Leave the Friction at at 0.4.</p>




<p>Now select the pool table again and add a Polygon Collider 2D component. This sets the pool table's collider on the visible areas of the object's sprite. Since we're using a PNG with transparency it ignores the the middle part. </p>



<p>Drag our newly-created Material into the Material field of the Polygon Collider 2D. At this point save everything and run the game. You should be able to hit the ball around the table. Colliders and Rigidbodys can be tricky, so double-check the values of those if things aren't working.</p>



<h3>Adding the Balls</h3>



<p>Next select the ball and make some changes to the Circle Collider and Rigidbody components. For the Circle Collider add the same Bounce Material we made earlier.</p>



<p>On the Rigidbody change these values:</p>

<ul><li>Mass: 2</li><li>Linear Drag: 0.5</li><li>Angular Drag: 0.5</li></ul>



<p>These settings change how the ball will interact with the environment. Heavier objects will have more impact, and the drag settings determine how much friction there is. Move the values down for a lighter ball that will move more and faster, or increase them for a heavier one.</p>



<p>Duplicate the ball and remove the Drag and Shoot script. Save up, hit Play, and try hitting the ball. It should bounce around when you hit it and respond to the walls in the same way. Duplicate the balls a few times and you have a pocketless game!</p>



<h3>Adding Pockets</h3>



<p>Duplicate one of script-less balls. Delete the Bounce Material and check the Is Trigger box.</p>



<p>Make a new script called BallManager. Select all the balls except the cue ball and drag this script onto them. Open it up and replace it with this:</p>


<pre>{`using UnityEngine;

public class BallManager : MonoBehaviour
{
    private void OnTriggerEnter2D(Collider2D collision)
    {
        Destroy(gameObject);
    }
}
`}</pre>


<p>This says that any time the ball comes into contact with the pocket (i.e. the area defined by the Circle Collider) it triggers the function and destroys itself.</p>



<p>Save your work, hit Play, and you have pool! This is where the tutorial ends. </p>



<h3>Ideas for Where to go Next</h3>


<ul><li>Add an AudioSource to the pool table to play a sound when a ball collides. Use the OnCollision2D component to access the collision in the same way we did with the pockets.</li><li>Add sound to the ball in the same way.</li><li>Change the drag position to the cue ball.</li><li>Replace the ball on the table when it goes in a pocket.</li><li>Make it into a real game.</li></ul>


</article>
  )

}