const apis = [
  {
    name: "URL Shortener",
    description: "Generate a \"short\" URL.",
    urls: [
      {
        url: "https://url-shortener-gamma-two.vercel.app",
        description: "POST to get a random URL."
      },
      {
        url: "https://url-shortener-gamma-two.vercel.app",
        description: "GET with params to recieve original URL."
      },
    ],
    skills: [
      "Nodejs",
      "Firebase",
      "Express",
      "Vercel"
    ],
    github: "private"
  },{
    name: "Grucha Libre",
    description: "Stats for the Gruchadors",
    urls: [
      {
        url: "https://battlers-api.vercel.app/getAll",
        description: "GET all players and their stats."
      },
    ],
    skills: [
      "Nodejs",
      "Firebase",
      "Express",
      "Vercel"
    ],
    github: "private"
  },
  {
    name: "Bio Generator",
    description: "Generate a random bio",
    urls: [
      {
        url: "https://bio-generator-api.vercel.app/",
        description: "GET a random biography"
      },
    ],
    skills: [
      "Nodejs",
      "Express",
      "Vercel"
    ],
    github: "https://github.com/gruman/bio-generator-api"
  },
  {
    name: "Excuse Rolodex",
    description: "Generate a random excuse",
    urls: [
      {
        url: "https://excuse-rolodex.vercel.app/",
        description: "GET a random excuse"
      },
      {
        url: "https://excuse-rolodex.vercel.app/100",
        description: "GET a number of random excuses based on the URL parameter"
      }
    ],
    skills: [
      "Nodejs",
      "Firebase",
      "Express",
      "Vercel"
    ],
    github: "Private"
  },
  {
    name: "How Do I Smell?",
    description: "Find out how you smell",
    urls: [
      {
        url: "https://how-do-i-smell.vercel.app",
        description: "GET a smell"
      },
    ],
    skills: [
      "Nodejs",
      "Express",
      "Vercel"
    ],
    github: "Private"
  },
  {
    name: "Cow Facts",
    description: "Get a random cow fact",
    urls: [
      {
        url: "https://cows-gruman.vercel.app/cows",
        description: "GET a cow fact"
      },
    ],
    skills: [
      "Nodejs",
      "Express",
      "Vercel"
    ],
    github: "Private"
  },
  {
    name: "Lorem Ipsum",
    description: "Filler text for projects that need filler text",
    urls: [
      {
        url: "https://lorem-pickup.vercel.app/",
        description: "POST a 'type' (e.g. cow) and 'num'"
      },
    ],
    skills: [
      "Nodejs",
      "Express",
      "Vercel"
    ],
    github: "Private"
  },
  {
    name: "Random Hex Color",
    description: "Delivers a random hex color alongside a properly-contrasted text color",
    urls: [
      {
        url: "https://random-hex.vercel.app",
        description: "POST to get a response"
      },
    ],
    skills: [
      "Nodejs",
      "Express",
      "Vercel"
    ],
    github: "Private"
  },
  {
    name: "Random Hex Color",
    description: "Delivers a random hex color alongside a properly-contrasted text color",
    urls: [
      {
        url: "https://fake-name-api.vercel.app",
        description: "GET a name. Use /num to get multiple."
      },
    ],
    skills: [
      "Nodejs",
      "Express",
      "Vercel"
    ],
    github: "Private"
  }
]

export default apis;