import { useParams } from 'react-router-dom';

import archivedata from '../constants/archive';

function App() {

  const { slug } = useParams();
  const project = archivedata.find(item => item.slug === slug);
  console.log(project)
  return (
    <article>
    <h4><a href={"/project/" + project.slug}>{project.name}</a></h4>

    <ul className="spiel">
      {project.spiel.map((item, index) => {
        return (
          <li key={index}>{item}</li>
        )
      })}
    </ul>
    {
      project.urls &&
      <>
        <h5>URLs:</h5>
        <ul className="spiel">
          {project.urls.map((item, index) => {
            return (
              <li key={"url" + index}><a href={item.url}>{item.label}</a></li>
            )
          })}
        </ul>
      </>
    }
    <p className="skilllist">
      {
        project.skills.map((i, idx) => {
          return (
            <span>{i}</span>
          )
        })
      }
    </p>
      </article>
  );
}

export default App;
