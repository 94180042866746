export default function App() {

  return(
    <article>
    <h2>Login with React Native Expo and Firebase</h2>
    <p className="date">Last updated 20/11/2023</p>
    <p className="lead" style={{color: "#111"}}>This is a tutorial to set up Firebase Authentication with a React Native Expo App. The user will be able to login, logout, create an account, and stay logged in after device resets.</p>
    <p>Full code is available on <a href="https://github.com/gruman/react-native-firebase-login">GitHub</a>.</p>
    
<h2>Setting Up</h2>
<p>Create a new Expo app:</p>
<pre>
  npx create-expo-app [app name]
</pre>
<p>Install Firebase:</p>
<code>{`yarn add firebase
or
npm install firebase`}
</code>
<p>Install Async Storage:</p>
<code>{`yarn add @react-native-async-storage/async-storage
or
npm install @react-native-async-storage/async-storage`}
</code>
<p>Setup a new <a href="http://firebase.blog">Firebase</a> app and enable Email and Password authentiation.</p>
<p>In the root directory of you app make a directory named /constants/ and add a file named firebase.js inside. Under Project Settings in Firebase add a web app and copy the initialization code into firebase.js.</p>
<img src={require('../../img/FirebaseSetup.png')} className="full-img" alt="Example of the initialization code. Machine-readable on GitHub." />
<p>The relevant files should look like this:</p>
<code>{`|-constants
    |-firebase.js
|-pages
    |-Home.js
    |-Login.js
|-App.js

`}</code>

<h2>App.js</h2>
<p>The App.js JS file acts as a router. If you're logged in you go to the home page, and if you're not you go the the login page. Everytime a page is accessed this runs.</p>
<p>Asyc storage is used to keep the user in memory over resets. You don't need to do this with regular React, but if you don't do it with React Native your users will constantly get logged out.</p>
<code>{`import React, { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import Home from './screens/Home';
import Login from './screens/Login';

import './constants/firebase';

export default function App() {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Function to retrieve user from AsyncStorage on app start
    const retrieveUser = async () => {
      try {
        // Retrieve user data from AsyncStorage
        const storedUser = await AsyncStorage.getItem('user');

        // If user data exists, set the user state with the retrieved data
        if (storedUser) {
          setUser(JSON.parse(storedUser));
        }
      } catch (error) {
        console.error('Error retrieving user from AsyncStorage:', error);
      } finally {
        // Set loading state to false once user data retrieval is complete
        setLoading(false);
      }
    };

    // Listen for authentication state changes using Firebase Auth
    const unsubscribe = onAuthStateChanged(auth, async (userCredential) => {
      if (userCredential) {
        // User is signed in
        setUser(userCredential);

        // Save user to AsyncStorage for persistent storage
        try {
          await AsyncStorage.setItem('user', JSON.stringify(userCredential));
        } catch (error) {
          console.error('Error saving user to AsyncStorage:', error);
        }
      } else {
        // User is signed out
        setUser(null);
      }

      // Set loading state to false once authentication state changes are handled
      setLoading(false);
    });

    // Retrieve user from AsyncStorage on app start
    retrieveUser();

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, []); // Empty dependency array ensures the effect runs only on mount and unmount

  // If the app is still loading, return null (or a loading screen)
  if (loading) return null;

  // Render the Home or Login component based on the user state
  return (
    <>
      {user ? <Home user={user} /> : <Login />}
    </>
  );
}
`}</code>

<h2>Login.js</h2>
<p>The user ends up here if they aren't logged in.</p>
<code>{`
import React, { useState } from "react";
import { StyleSheet, View, Text, TextInput, Button } from "react-native";

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";

export default function App() {
  // Get the authentication instance
  const auth = getAuth();

  // State for user information, input fields, and login credentials
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logEmail, setLogEmail] = useState("");
  const [logPassword, setLogPassword] = useState("");

  // Function to handle the creation of a new user account
  function handleCreate() {
    if (email && password) {
      // Create a new user with the provided email and password
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // User account created successfully
          console.log(userCredential.user);
        })
        .catch((error) => {
          // Handle errors during account creation
          alert(error.message);
        });
    } else {
      // Alert the user if fields are missing
      alert("Please fill out both fields.");
    }
  }

  // Function to handle user login
  function handleLogin() {
    if (logEmail && logPassword) {
      // Sign in with the provided email and password
      signInWithEmailAndPassword(auth, logEmail, logPassword)
        .then((userCredential) => {
          // User successfully signed in
          setUser(userCredential.user);
        })
        .catch((error) => {
          // Handle errors during login
          alert(error.message);
        });
    }
  }

  return (
    <View style={styles.container}>
      
      <TextInput
        style={{ marginBottom: 20 }}
        value={logEmail}
        onChangeText={(e) => setLogEmail(e)}
        placeholder="Email"
      />
      <TextInput
        style={{ marginBottom: 20 }}
        secureTextEntry={true}
        value={logPassword}
        onChangeText={(e) => setLogPassword(e)}
        placeholder="Password"
      />
      
      <Button onPress={() => handleLogin()} title="Login" />

      <TextInput
        style={{ marginBottom: 20, marginTop: 20 }}
        value={email}
        onChangeText={(e) => setEmail(e)}
        placeholder="Email"
      />
      <TextInput
        style={{ marginBottom: 20 }}
        secureTextEntry={true}
        value={password}
        onChangeText={(e) => setPassword(e)}
        placeholder="Password"
      />
      <Button onPress={() => handleCreate()} title="Create account">
        Create Account
      </Button>
    </View>
  );
}

// Styles for the App component
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
});
`}</code>

<h2>Home.js</h2>
<p>The user end up here when they are logged in. You can see a good example of App.js working when you log out - it knows to send you back to the Login page.</p>
<code>{
  `
  import React from "react";
  import { StyleSheet, View, Text, Button } from "react-native";
  import { getAuth, signOut } from "firebase/auth";
  
  export default function Home(props) {
    // Get the authentication instance
    const auth = getAuth();
  
    return (
      <View style={styles.container}>
        <Text>Home page</Text>
        <Text>My ID is: {props.user.uid}</Text>
        <Button onPress={() => signOut(auth)} title="Sign out" />
      </View>
    );
  }
  
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center"
    },
  });
  `}</code>
 <p>Full code is available on <a href="https://github.com/gruman/react-native-firebase-login">GitHub</a>.</p>
   
</article>
  )

}