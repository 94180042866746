const tabs = [
  {
    title: 'Wintersleep - Weighty Ghost',
    html: `Wintersleep - Weighty Ghost
    
    The rhythm is a bit tricky on this one, but you just have to play the G before finishing every line. You can use these patterns to count along:
    
    Verse
    
    1 & 2 & 3 & 4 &
    G
    1 & 2 & 3 & 4 &
    C G
    
    Chorus
    
    1 & 2 & 3 & 4 &
    G C/G G
    1 & 2 & 3 & 4 &
    C G
    
    Capo II
    
    C: x32013
    G: 320003
    G/C: 3x2013
    
    OR
    
    Use these chords without a capo (*Thank you Concentric for the comment). It sounds more like the record with these:
    
    C C/G C G*
    e 0 0 0 0
    B 5 5 7 5
    G 6 7 7 6
    D 7 7 0 0
    A 0 0 0 0
    E 0 0 0 0
    Intro (play chorus pattern):
    G G/C G
    C
    Verse
    G
    I got out of bed today,
    C
    Swear to God I couldnt see my face
    G
    I got out of bed today
    C
    staring at a ghost
    Verse
    G
    Who forgot to float away,
    C
    didnt have all that much to say
    G
    Wouldnt even tell me his own name
    C
    And whered my body go?
    Chorus
    G G/C G
    Where oh whered my body go?
    C
    Africa or Mexico?
    G G/C G
    Oh whered my body go?
    C
    And where did my body go?
    Chorus
    G G/C G
    Woah oh, have you seen my ghost?
    C
    Seen my ghost, seen my ghost?
    G G/C G
    Woah oh, have you seen my ghost?
    C
    Staring at the ground?
    Chorus
    G G/C G
    Woah oh, have you seen my ghost?
    C
    See my ghost, see my ghost
    G G/C G
    Woah oh, have you seen my ghost?
    C
    Sick of those goddamn clouds
    Bridge (play chorus pattern):
    G G/C G
    C
    Verse
    G
    Are you some kind of medicine man?
    C
    Cut the demons out of my head
    G
    You cant kill something thats already dead
    C
    So leave my soul alone
    Verse
    G
    I dont need no surgery
    C
    Take those knives away from me
    G
    Just wanna die in my own body
    C
    A ghost just needs a home
    Chorus
    G G/C G
    Woah oh, have you seen my ghost?
    C
    See my ghost, see my ghost
    G G/C G
    Woah oh, have you seen my ghost?
    C
    Staring at the ground?
    Chorus
    G G/C G
    Woah oh, have you seen my ghost?
    C
    See my ghost, see my ghost
    G G/C G
    Woah oh, have you seen my ghost?
    C
    Sick of those goddamn clouds
    Chorus
    G G/C G
    Woah oh, have you seen my ghost?
    C
    See my ghost, see my ghost
    G G/C G
    Woah oh, have you seen my ghost?
    C
    Staring at the ground?`
  },
  {
    title: "TV on the Radio",
    html: `
    TV on the Radio -Golden Age
    
    I use the following positions for chords:
    C: 332010
    G: 320033
    Bb: 113331
    F: 133211
    Power chords will sound fine.
    Verse riff:
    A|--3-1-3-1--3-1-3--1-1-1--|
    Chorus riff:
    D|-----5--------3--|
    A|--3--------1-----|
    Heart beat sounding
    Ricocheting in their cage
    Thought I'd lose my balance
    With the grounds bounce and sway
    And all this violence
    And all this goes away
    And the vibes that rise like
    Fireflies illuminate our play
    Some light being
    Pulled you up from night's party
    Said clap your hands
    If you think your soul is free
    And the silence was astounding
    'cept some "Oh Lord!! Mercy Me's"
    And oh you can't stop what's comin' up
    You're never gonna stop gonna live it up
    And oh it's gonna drop gonna fill your cup
    And oh it's gonna drop gonna fill your cup
    C G Bb F
    The age of miracles
    C G Bb F
    The age of sound
    C G Bb F
    Well there's a Golden Age
    C G Bb F
    Comin' round, comin' round, comin' round
    Give it up
    'stead of grabbing for decay
    What we viewed as gold
    I believe pollutes this space
    And its grace ascending
    Like a snake up your tree
    Up your happy ending understanding
    All your s'pposed to be
    Let it move right in
    Let it kiss your face
    Let it sow your skin
    In perpetual embrace
    Like I said "Love's Light is Laughter"
    Like the sun spitting happiness into the hereafter
    Oh here it comes like a natural disaster
    Ah blowing up like a ghetto blaster
    Ah here it comes, bring it faster
    Ah here it comes, bring it faster
    C G Bb F
    The age of miracles
    C G Bb F
    The age of sound
    C G Bb F
    Well there's a Golden Age
    C G Bb F
    Comin' round, comin' round, comin' round
    C F
    Love, don't you falter
    C
    Burning hearts
    Dragged behind
    F
    The horses dancing on the altar
    C
    Hooves breaking Gods
    F
    To diamond dust and stars
    And there you are…
    Now we're all allowed to breathe
    Walls dissolve
    With the hunger and the greed
    Move your body
    You've got all you need
    And your arms in the air stir a sea of stars
    And oh here it comes and it's not so far
    All light beings
    Come on now make haste
    Clap your hands
    If you think you're in the right place
    Thunder all surrounding
    Aw feel it quake with the joy resounding
    Palm to the palm you can feel it pounding
    Never give it up you can feel it mounting
    Oh its gonna drop gonna fill your cup and
    Oh its gonna drop gonna fill your cup
    C G Bb F
    The age of miracles
    C G Bb F
    The age of sound
    C G Bb F
    Well there's a Golden Age
    C G Bb F
    Comin' round, comin' round, comin' round
    C G Bb F
    The age of miracles
    C G Bb F
    The age of sound
    C G Bb F
    Well there's a Golden Age
    C G Bb F
    Comin' round, comin' round, comin' round
    C G Bb F`
  },
  {
    title: "Tim Fite - Big Mistake",
    html: `Time Fite - Big Mistake
    Capo VI
    C: x32010 -or- x32013
    Em: 022000 -or- 022003
    Am: x02210 -or- x02x15
    F: 133211
    The alternate chords, while a bit tricky to play, sound more like the record.
    The entire song, save the "hold Am" parts, is:
    C Em Am F
    Show me the best that you got, and I'll show you one better
    Show me your reddest rose, and I'll show you one redder
    Tell me a dirty joke, and I'll laugh it off lightly
    If I tell you a dirty joke, you might not like me
    Everyone gets to make one big mistake
    And if you're waitin' on me
    Well, I guess you're gonna have to wait (hold Am)
    Ti-time-timing is everything if you've got nothin' but time
    (I got nothin' but time)
    And a lie don't mean nothin' if nobody knows when you're lying
    (lie when you lie)
    So lie when you do, lie when you don't
    Lie when you didn't, lie till you lie, lie, lie, lie
    They're wonderin' if anybody else is lying
    (end Am)
    Everyone gets to make one big mistake
    And if you're waitin' on me
    Well, I guess you're gonna have to wait (hold Am)
    'Cause I'm savin' mine up for a very, very special day
    When I can fuck it all up in the most spectacular way (hold Am)
    Everyone gets to make one big mistake
    And if you're waitin' on me
    Well, I guess you're gonna have to wait
    And wait, and wait, and wait, and wait, and wait
    Everyone gets to make one big mistake
    (mistake, mistake, mistake, mistake, mistake, mistake, mistake, mistake)
    And if you're waitin' on me
    Well, I guess you're gonna have to wait (you're gonna have to wait)
    'Cause I'm savin' mine up for a very, very special day
    (it doesn't even matter if it don't even matter)
    When I can fuck it all up in the most spectacular way (hold Am)
    Show me the best that you got, and I'll show you one better
    Show me your reddest rose, and I'll show you one redder (hold Am)
    Tell me a dirty joke, and I'll laugh it off lightly
    If I tell you a dirty joke, you might not like me (hold Am)`
  },
  {
    title: "Soso",
    html: `
    Soso - The First of a Thousand Goodbyes
    
    Em Bm Am
    I guess I thought his clothes and personal possessions
    would somehow make me feel his presence.
    But his mittens with mismatching thumbs are
    dumb without his hands to animate them.
    I guess in the same way i thought that if i lay myself down in this field something would be revealed
    if i listen hard, i listen hard
    Things are rarely as i expect
    Echoing Hallmark card sentiments like
    “Please accept our deepest sympathies”
    and quotings with great regret
    A lifetime of generating assets can be a real pain in the ass
    Shifting through old boxes of shit in the garage
    And when old people die there are no casserole and pies at the end of the rainbow
    Just a lot of grown ups with wet eyes
    Red faces and neck ties from ten years ago
    On the last happy or sad occasion
    And don' forget to get the good shirt laundered
    I've squandered all my thoughtful words in the first third of my life
    And i said i wasn't going to write anymore songs like this
    I don't want to write anymore songs like this
    I, I know
    I know this the first of a thousand goodbyes
    I, I know
    I know this is the first of ten thousand goodbyes`
  },
  {
    title: "Songs",
    html: `
    Songs: Ohia - Captain Badass
    
    The entire song is three chords (G#m Eb C#m) with occasional embellishments.
    You can either play it with barre chords, or slap a capo on the 4th fret and use more open chords.
    Chords (bracketed chords are embellishments to throw in occasionally):
    G#m: 466444 (466454)
    Eb: x68xxx (x69xxx)
    C#m: x46654
    The entire song is G#m Eb C#m G#m
    Alternate chords (bracketed chords are embellishments):
    CAPO IV
    Em: 022000 (022010)
    B: x24xxx (x25xxx)
    Am: x02210
    The entire song is Em B Am Em
    Bass:
    G|-----------------------
    D|-----------------------
    A|-----6---4-6-4---------
    E|-4-6---7-------4-6-7-6-
    Lyrics:
    departure and worry
    and worry, and worry
    still shake me
    resistance failed
    resistance failed
    and friendship failed
    and friendship failed
    as lovers we did not fail
    as lovers we did not fail
    brown eyes your pulse is getting hotter
    brown eyes your pulse is getting quicker
    quote Captain Badass,
    "i am setting your heart on fire
    so when you leave me
    i will burn on in your soul"
    you won't have to think twice
    if it's love you will know
    we get no second chance in this life
    we get no second chance in this life
    so a hot pulse is alright
    so a quick pulse is alright
    so a hot kiss is alright
    so a long kiss is alright
    so a long night is alright
    and all night is alright
    there ain't no contest
    against the final day
    we'll rise above us either way
    we're either greeted
    by life or it's reverse
    then each day greeted
    by fortune or it's reverse
    will you stand up for your one chance
    will you stand up for love
    we get no second chance in this life
    we get no second chance in this life
    you won't have to think twice if it's love
    you will know`
  },
  {
    title: "The Tragically Hip - Stay",
    html: `
    The Tragically Hip - Stay
    
    For the intro part, I just play octaves (i.e. for E I play x7x9xx, D is x5x7xx, etc.)
    E D C B A G \\
    F F C / x2
    F C
    You did the best that you could do.
    G
    You were a great crew,
    F
    who tried to nurture and preserve your
    G
    faith in you.
    F C
    And with the bureau chiefs
    G
    and the shrugging spies
    F G
    You could stay, but why.
    G
    Am
    You see a light and then another
    F
    and everything you fought for
    C
    naughts uncovered.
    Youfe not a fighter, youfe a lover.
    Am
    You got no business in here, brother
    F G
    so… stay.
    Stay.
    E D C B A G C
    E D C B A G \\
    F F C / x2
    F C
    Is it the worst that you could do?
    G
    You were a great you,
    F
    who tried to nurture and preserve your
    G
    faith in you
    F C
    and with the bureau chiefs
    G
    and the shrugging spies
    F G
    you could stay and why.
    Am
    Cause you see a light and then another.
    F
    Everything you thought you
    C
    soughts uncovered.
    Youfe a fighter and a lover
    Am
    and theres no one up above her
    F G
    so …stay.
    Stay.
    E D C B A G C
    F C
    F
    All things being balanced,
    C
    its balanced and called balancing
    F
    somewhere beyond everything its being
    balanced not for the
    C
    sake of balance but balancing between
    the throes of learning
    Bb
    and the entire thing
    C
    entirely
    Bb
    balancing.
    E D C B A G C`
  },
  {
    title: "Ron",
    html: `
    Ron Sexsmith - These Days
    
    Capo IV
    Intro: G C
    G
    Promises are made to be broken
    C
    Haven't you heard?
    G
    He said he'd never break your heart
    C
    Now haven't you learned?
    D
    You believed in the words
    C
    In all that they meant
    D
    Oh, but love is not some popular song
    C D
    Filled with empty sentiment
    Am D
    That's what passes for love
    Am D
    That's what passes for love these
    G C
    Days
    G C
    Oh these days
    G
    It won't take a miracle, darlin'
    C
    Just keep it real
    G
    This world can make us cynical, darlin'
    C
    I know how it feels
    D
    You took it to heart
    C
    What they said on the screen
    D
    Oh, but love is not just playing a part
    C D
    In some very scripted scene
    Am D
    That's what passes for love
    Am D
    That's what passes for love
    Am D
    That's what passes for love these
    G C
    Days
    Am D C
    No one can complete you or make you whole
    G
    Well
    Am D
    But love will come to greet you half way
    C G C
    Though the streets are never paved with gold
    G
    Promises are made to be broken
    C
    Haven't you heard?
    G
    He said he'd never break your heart
    C
    Now haven't you learned?
    D
    You believed in the words
    C
    In all that they meant
    D
    Oh, but love is not some popular song
    C D
    Filled with empty sentiment
    Am D
    That's what passes for love
    Am D
    That's what passes for love
    Am D
    That's what passes for love these
    G C
    Days
    G C`
  },
  {
    title: "Rent",
    html: `
    RENT Musical - You'll See
    
    The intro part should be involve some sort of pick pattern
    to sound like the record. The notes in parenthesis (G# and Bb)
    are just individual notes to be played on the bass strings.
    C C/b Am (G#) (Bb)
    ROGER
    What Happened to Benny?
    What Happened to his Heart
    And the Ideals he Once Pursued?
    BENNY
    And the Owner of that lot Next Door
    Has the Right to do with it as he Pleases
    ROGER
    Happy Birthday, Jesus
    G
    BENNY (spoken)
    The Rent
    MARK
    You're wasting your time
    ROGER (spoken)
    We're broke
    F
    MARK
    And you Broke your Word-This is Absurd
    G
    BENNY
    There is One Way you Won't Have to Pay
    F
    ROGER
    I Knew It
    G
    BENNY
    Next Door
    The Home of CyberArts
    You see
    F
    And now that the Block is Rezoned
    G
    Our Dream can Become
    F
    A Reality
    C F
    Youll See Boys
    C F
    Youll See Boys
    VERSE
    (spoken)
    A State of the Art
    Digital
    Virtual
    Interactive Studio
    I'll Forego your Rent
    And on Paper Guarantee
    That you can Stay here for Free
    If Youll Do Me One Small Favor
    MARK (spoken)
    What?
    BENNY (spoken)
    Convince Maureen
    To Cancel her Protest
    MARK (spoken)
    Why not get an Injunction
    Or Call the Cops?
    BENNY
    (spoken)
    I did, and They're on Stand By
    G
    But my Investors Would Rather
    F
    I Handle this Quietly
    ROGER (spoken)
    You can't Quietly Wipe Out
    An Entire Tent City
    Then Watch "Its Wonderful Life"
    On T.V.
    BENNY
    You Wanna Produce Films
    And Write Songs
    You Need Somewhere to Do It
    Its What we Used to Dream About
    Think Twice Before You Poo Poo It
    C F
    Youll See Boys
    Youll See Boys
    Eb C
    Youll See the Beauty of a Studio
    Eb
    That Lets Us Do Our Work
    C
    And Get Paid
    Eb
    With Condos on the Top
    C
    Whos Rent Keeps Open our Shop
    Eb
    Just Stop the Protest
    D
    And Youll Have It Made
    C
    Youll See
    Or Youll Pack`
  },
  {
    title: "Okk",
    html: `
    Okkervil River - Unless Its Kicks
    
    I've arranged this to be capo'd on the fourth fret because thats how I play it on
    guitar. It sounds more like the record if its done with barre chords.
    This is the “riff” thats used throughout the song for the C and G chords:
    1 & 2 & 3 & 4 & x2
    C C6 C
    1 & 2 & 3 & 4 & x2
    G G6 G
    C6: x3x210
    G6: 3x2003
    Capo IV
    Intro:
    C G x 2
    C
    What gives this mess some grace unless its
    G
    kicks, man - unless its
    Am
    fiction, unless its
    G
    sweat or its songs? What
    C
    hits against this chest unless its a
    G
    sick mans hand, from some
    Am
    mid-level band? Hes been
    G
    driving too long on a
    C
    dark windless night, with the
    G
    stereo on, with the
    Am
    towns flying by and the
    F G
    ground getting soft. And a
    C
    sound in the sky, coming
    G
    down from above, it sur-
    Am
    rounds you and sighs and is
    F G
    whispering of what
    C
    pulls your body down, and that is
    G
    quicksand. So climb out
    Am
    quick, hand over hand, before your
    G
    mouths all filled up. What
    C
    picks you up from down unless its
    G
    tricks, man? When I've been
    Am
    fixed I am convinced that I will
    G
    not get so broke up again. And on a
    C
    seven day high, that
    G
    heavenly song punches
    Am
    right through my mind and just
    F G
    hums through my blood. And I
    C
    know its a lie, but I'll
    G
    still give my love. Hey, my
    Am
    hearts on the line for your
    F G
    hands to pluck off. Oh…
    C G Am G
    C
    What gives this mess some grace unless its
    G
    fictions - unless its
    Am
    licks, man, unless its
    G
    lies or its love? What
    C
    breaks this heart the most is the ghost of some
    G
    rock and roll fan, just floating
    Am
    up from the stands with her
    F G
    heart opened up. And I want to tell her, "Your
    C
    love isn't lost," say "my
    G
    heart is still crossed!" Scream, "you're
    Am
    so wonderful! What a
    F G
    dream in the dark - about
    C
    working so hard, about
    G
    growing so stoned, trying
    Am
    not to turn off, trying
    F G
    not to believe in that lie all on your
    C
    own."
    G
    La la la
    Am
    la Oh oh oh
    F
    Oh
    G
    C G Am F G x2
    End on C/G ( x32013 )`
  },
  {
    title: "Of",
    html: `
    Of Monsters and Men - Lakehouse
    
    The picking part:
    G
    E|---------------------------------|
    B|---------------------------------|
    G|---0---0---0---0---0---0---0---0-|
    D|---0---0---0---0---0---0---0---0-|
    A|---------------------------------|
    E|-3---3---3---3---3---3---3---3---|
    C
    E|---------------------------------|
    B|---------------------------------|
    G|---0---0---0---0---0---0---0---0-|
    D|---2---2---2---2---2---2---2---2-|
    A|-3---3---3---3---3---3---3---3---|
    E|---------------------------------|
    There are a lot of people playing a lot of guitars in this band,
    so you won't be able to play all the notes all the time.
    Choose one of the ones listed below or switch it up (the singer
    does this as you can see in the video), and have fun with it.
    The "B" with the E on the bottom sounds really out of place for
    most of the song, but you can hear it during the outro.
    The chords
    G: 320033 or 3x0033 or 320003 or 3x0003
    C: x32033 or x3x033 or x3x010 or x32013
    B: x20033 or x2x033 or 0244xx
    G C G C
    G C
    Oh I miss the comfort of this house
    G
    Where we are where we are
    C
    Where we are where we are
    G C
    The floor under our feet whispers out
    G C
    Come on in come on in, where it all begins
    The tallest man I've ever seen afloat,
    On a boat, on a boat
    On a boat, on a boat
    He keeps his only son close by
    In a bag, in a bag
    In a bag on his back
    G C
    Can you chase this fire away
    Can you chase this fire away
    We climbed up to the top in worn out shoes
    But she ran down she ran down
    She ran down to the house
    A fox that gains our trust, but then breaks it as he walks
    Away from us, away from us
    Can you chase the fire away
    Can you chase the fire away
    B C G
    In the fall, we sleep all day
    B C G
    In the fall, we sleep all day
    G
    Where we are, where we are
    C
    Where we are, where we are
    G
    Where we are, where we are
    C
    Where we are, where we are
    Can you chase this fire away (Where we are, where we are)
    Can you chase this fire away (Where we are where we are)
    In the fall, we sleep all day (Where we are, where we are)
    In the fall, we sleep all day (Where we are, where we are)
    B C G
    La la la la la la la la la la la la la la (repeat to end)`
  },
  {
    title: "k-os",
    html: `
    k-os (ft. Kevin Drew and Sam Roberts) -  Valhalla
    
    The record version is essentially the same riff repeated over and over again.
    When he plays it acoustically, the guitarist uses a lot of variants on G and C chords;
    mostly hanging on a G. You'll be pretty safe sticking with G for the whole song and
    just switching up to C on "the world is a ghetto" and "feel your sorrow" in the chorus.
    Riff (play throughout):
    G C
    A|--------------------3--|
    E|---3-(repeat a lot)----|
    Chords:
    G: 320033
    C: x32010
    (Chorus)
    Ghetto Heaven
    The world is a ghetto
    Freedom, oh yeah
    Feel your sorrow
    (Verse 1)
    5:35 and I'm living senseless
    Just another homie picking on the defenceless
    Don't request yet, nothing really to mention
    Lookin through the mirror and I'm making a censure
    Whoa, black people in the morning breeze
    Came around the corner and she caused me to freeze up
    Ease up, watch how the world could tease us, ease us
    But only G.O.D can please us
    Hey, got my ride and my Momma's too
    Show me how to roll without breakin the rules
    Payin my dues, MC's droppin out choose
    Decides to get with lyrical facts, don't stop
    Whoa, black people time to shine
    Everything is fine when you're losing your mind
    I'm like a black alley cat, freestyle batter-up
    Doesn't get better than that, is that a gat in the back?
    (Chorus)
    (Verse 2)
    And the Valkyrie's blood runs red through the halls of the dead
    oh the warrior's blood runs red through the hearts of the dead
    the Valkyrie's blood runs red through the halls of the dead
    And the life you're been lookin for in the world that you live 2x
    In the name, we were all born in the flame
    Not the same, but under the moon on the desert plane
    We roam, home, take the coast ghost
    I saw with my naked eye, way to close now
    I was the son of a man on a mission
    Who worked with his hands and brought him the former land
    In the inter-land, swingin the mic like an axe
    With a fear in the atmosphere, layin down tracks
    (Chorus)`
  },
  {
    title: "Gord",
    html: `
    Gordon Downie - Into the Night
    
    It sounds more like the record if you make the C chords as
    8th fret barres (8 10 10 9 8 8), or add a high G (x 3 2 0 1 3).
    F
    I saw you running with your friends
    G
    I called your name too loud - 'come back'
    F
    so many times it might have been embarrassing
    G
    if you hadn't come walking back
    C
    F
    Here's where I shook you by the shoulders
    G
    shoved you up against a truck - 'what's up'
    F
    it was a picture of someone getting older
    G
    and of someone growing up
    C
    A C
    someone growing up
    A C
    someone growing up
    A G
    someone growing up
    C
    F
    it was like you were in disguise
    G
    you were so nonchalant - 'come on'
    F
    as though your well half-lidded eyes said,
    G
    'what the hell do you want?'
    C
    F
    Well I must've said something
    G
    for you to feel I was alright - 'alright'
    F
    We had a great conversation
    G
    that went on well into the night
    C
    A C
    went on well into the night
    A C
    went on well into the night
    A G
    went on well into the night
    C
    went on well into the night`
  },
  {
    title: "Gord",
    html: `The intro part of this is very jangly, so just bash at the strings to your hearts desire.
    note For the intro, play the E5 as x799xx and the Emaj7(no 3rd) as x798xx.
    Don't worry about hitting the open strings, it sounds good if you just bang at it.
    Intro:
    E5 Emaj7(no 3rd) E5 E5
    A E
    So this is fucking off by degrees and
    A E
    I suppose we turned out to be not-quite-Hawaii but
    A B
    I can float back to sleep cause at least you're lying to me like
    A
    music that dances from glowing apartments as
    E
    shadows entwine into a creamy darkness like
    A
    jewelry hung down from rich silhouettes
    E B
    portrays on the sidewalk where wetness reflects all the
    A
    colours of evening and the onset of lights
    B E
    like the promise of nothing, sweet nothing, tonight.
    B
    Ahhhhh
    C E C E C B
    E
    A E
    So this is enacting ecstasy and
    A E
    I suppose we turned out to be bathroom graffiti but
    A B
    I can float back to sleep cause at least youre lying to me like
    A
    shoveling hope into the infinite us til the
    E
    world surges in yelling, this is a drug bust
    A
    might turn up the heat and make us into one person
    E B
    but then the temperature plunges and the predicament worsens
    E
    til were a fleck of new snow on the eyelash of a cow
    B
    and we melt away, melt away,
    E
    now
    B
    Ahhhhhh
    C E
    C E
    Melt away melt away now
    C B
    Melt away melt away now
    Melt away now
    E
    *During the bridge, you can play this as the lead:
    g|-5-7-9-11-12-11-12-14-16-|
    It is repeated 3x with a variation at the end`
  },
  {
    title: "Ghost",
    html: `
    Ghostpoet - Liiines
    
    Piano arranged for guitar.
    The entire song is D E B A using this pattern:
    D E B A
    E|--------------------------------|
    B|--------------------------------|
    G|----7-7-----7-7-----7-7-----7-7-|
    D|----7-7-----7-7-----7-7-----7-7-|
    A|-5-------7----------------------|
    E|--------(0)------7-------5------|
    I keep on scribbling
    In the spare room Im living in
    Bodys here but Im living in
    Why do I keep wasting time
    I keep on writing, writing
    But them folk aint biting, biting
    Maybe the bait ain't juicy enough
    So Im switching, chucking spice in,
    Add some flavor, up the heat,
    silly rhymes and three-time beats, yeah
    But that aint me - nah, nah, nah
    It just aint me
    So like me or lie me
    These are the words that just be true
    Sending them out with love and faithfully,
    Hopefully, they'll mean something to me cause…
    Life is too short to store our grudges
    Life is too long to make no plans, plans
    Im counting up time that I aint using (1,2,3,4…)
    I need a good use for all these lines, and lines, and lines, and lines
    And lines, and lines, and lines, and lines…
    [Repeat all]`
  },
  {
    title: "Cit",
    html: `
    Citizen Cope - More than it Seems
    
    Citizen Cope/Clarence Greenwood has a very restrained playing style
    in that he rarely plays full chords. Most of the guitar work in this song
    is with thirds; you can try these chords:
    G: 320xxx / 325xx
    C: x320xx / x325xx
    A: 542xxx
    The entire song is: G C A G
    Lyrics:
    Your so Pure(ah)
    Your heart is full of
    All i ever need
    And i cant take it
    I cant speak
    So i just sit here and stare
    My adorer, let me tell you a story
    About someone in me
    Now its gunna be surrounding me
    Break my silence
    Yea its a little bit more than it seems
    We can make outside my dreams
    Yea its a little bit more than it seems
    We can make outside my dreams
    My adorer, they say i dont know ya
    I sure do know
    Sure do miss ya
    I can't take it
    I can't take it
    My adorer, your heart is full of
    All ill ever need
    I cant take it
    I cant speak
    Break my silence
    Yea its a little bit more than it seems
    We can make outside my dreams
    Yea its a little bit more than it seems
    (We can make it) We can make outside my dreams
    Yea its a little bit more than it seems
    (We can make it) We can make outside my dreams
    Yea its a little bit more than it seems
    (We can make it) We can make`
  },
  {
    title: "Casta",
    html: `
    Castanets - Three Days, Four Nights
    
    Most of the song is played very sparsely; just hitting a few notes here and there.
    Replace the chords with these if you want to play it without a capo:
    Am -> Bm
    G -> A
    F -> G
    E -> F#
    Capo II
    Am G
    There's three days, and four nights to go
    F E
    And I wont be with you anymore
    Am F
    Four nights like this to
    Am
    go
    Am G
    In the trees like a freeway with that wind
    F E
    A devil howling, silent again
    Am F
    And the chill of that sheet
    Am
    On your skin
    Am G F E Am F Am x2
    Solo:
    Played with a slide (or not), without a capo.
    e|------------------------------------------------------|
    b|--10/12-12-12--12--10/12-12-12--12~~~-----------------|
    e|--14/15-15-15--14/15-15-15---14/15-15-15---\\12--12/14-|
    b|------------------------------------------------------|
    e|--20---19---19/20-20-20---19/20-20-20----19-----------|
    b|------------------------------------------------------|
    The last note is up close to the bridge.
    Am
    We aint free my love
    G
    We are not our masters
    F
    Without side looks faster
    E
    Drowned and distanced
    Am
    Rest and listen
    F
    As I cross these miles
    Am
    I try to tell you I love you
    Am
    Am G F E Am F Am X2
    Am
    F
    The walk we take
    G
    In the rain today
    E
    Your feet in your boots
    Am
    On the mud of the graves
    F Am
    The way we refuse to be saved
    Am F Am
    The way we refuse to be saved
    Am F Am
    The way we refuse to be saved
    Am`
  },
  {
    title: "Buck",
    html: `Buck 65 - Indestrucable Sam
    
    This is done on a 5-string banjo with some heavy droning on the G string
    (the small one at the bottom thats pegged halfway up the neck).
    You'll be safe if you just let it ring out the whole time.
    Be sure to let the G drone string ring throughout the song to get the proper sound
    Riff 1:
    d|--------------------------------------------
    B|---------1^3-----------3^1------------------
    G|-0-0-0^3-----0-0--0^3-------3--0----0-0-0^3-
    D|--------------------------------------------
    g|--------------------------------------------
    ------3^5-3^5-3^5----------
    -1^3--3---3---3---3\\1------
    ------------------------3-0
    
    Riff 2:
    d|--3^5-----3^5-3^5---3^5-3^5-----3^5-3^5---------
    B|--3---------3---3---3---3-------3---3----3\\1----
    G|---------------------------------------------3-0
    D|------------------------------------------------
    g|------------------------------------------------
    Lyrics:
    Well this heres the story of Samuel Donbay
    A man like any other at the end of a long day
    Guts and muscles and maybe a little more
    He worked as a grave digger after the civil war
    Miles around competition burned with the deepest rage
    The work was hard and Sam did it for the cheapest wage
    Seemed that the other Mans shoes didnt fit him
    When he was out working the others were out to get him
    They called him indestructible Sam,
    He promised he would die with a shovel in his hands
    Thats right, they called him indestructible Sam
    He promised he would die with a shovel in his hands
    Diggin all day Sams pockets got richer
    But the others were wishing he was out of the picture
    So late one night some of the competition went to see Doctor Beauregard
    a local magician
    They asked if he could help put things in reverse
    They pooled all their money for a fifty dollar curse
    Next morning at work was a commotion
    Digging a grave Sam heard an explosion
    Followed by a moaning, cursing and mumbling
    From the nearby bushes a figure came stumbling
    Sam shrugged his shoulders and surveyed the damages
    Later Dr Beauregard was seen wearing bandages
    Worse for wear he wasnt taking suggestion
    Said hed hex anyone that asked him any questions
    The coast wasnt clear trouble kept lurking
    The others kept scheming and Sam kept working
    They called him indestructible Sam,
    He promised he would die with a shovel in his hands
    Thats right, they called him indestructible Sam
    He promised he would die with a shovel in his hands
    The other gravediggers lamented their blown chance
    Decided to take matters into their own hands
    Thoughts full of poison and making a trap
    They blew up the shed where Sam was taking a nap
    The others were astonished and totally annoyed
    Cause Sam walked away while the shed was destroyed
    Next they tied him up and they were sure that they got him when they
    threw him in a lake and watched him sink to the bottom
    Below and behold and to the others dismay
    Sam was back at work again the very next day
    They burned his house down to the ground they were possessed
    And when that didnt work they finally shot him in the chest
    So hold your head low and fly the flag half mast
    But it was actually Samuel who had the last laugh
    He recovered again, lord be praised
    He lived to be a hundred and dug the others graves
    They called him indestructible Sam,
    He promised he would die with a shovel in his hands
    Thats right, they called him indestructible Sam
    He promised he would die with a shovel in his hands
    And he did
    They called him indestructible Sam,
    He promised he would die with a shovel in his hands
    Thats right, they called him indestructible Sam
    And he died with a shovel in his hands
    And this is an even true song
    It was wrote on account of old Sam workin so hard
    And I reckon thats how it ought to be
    From John Zytaruk, Buck 65's touring guitarist
    
    Just lettting you know that Old Man Luedecke played his banjo part on this song using the clawhammer technique and that the tuning is actually G-modal, also known as Mountain Minor or Sawmill and involves raising the pitch of the second string up to C. Once you’re in that tuning, the whole sound of the song will come together much better. All the best-John`
  },
  {
    title: "Bon",
    html: `
    Bon Iver - Skinny Love
    
    Tuning: G C C G C C
    Note: on the record, he's tuned a bit down.
    Since the top two strings are the same,
    it's best just to tune based on them.
    Am: 204000 -or- 200000
    C: 507000 -or- 000000
    D: 707000 -or- 700000
    C/B: 407000 -or- 400000
    or -
    Tuning: E A D G B e (standard)
    *Note: This is only an interpretation for standard tuning.
    Chords are labeled to coincide with the way I named
    the open-tuned version.
    Am: x0201x
    C: x3201x
    D: x5403x
    C/B: x2x01x
    Here are a couple of the riffs he uses throughout the song.
    Since it's in an open you can let the other strings ring out:
    C|-------------------|-------0---------|
    C|-------------------|-7-9-7---7-9-7-5-|
    G|-7-9-7-5---7-9-7-5-|-----------------|
    C|-------------------|-----------------|
    C|-------------------|-----------------|
    G|-------------------|-----------------|
    Intro:
    Am C
    Am C
    A C
    D Am C
    Verse:
    Am C
    Come on skinny love just last the year
    Am C
    Pour a little salt we were never here
    Am C
    My, my, my, my, my, my, my, my
    D Am C
    Staring at the sink of blood and crushed veneer
    Am C
    I tell my love to wreck it all
    Am C
    Cut out all the ropes and let me fall
    Am C
    My, my, my, my, my, my, my, my
    D Am
    Right in the moment this order's tall
    Chorus:
    C
    I told you to be patient
    C/B Am
    I told you to be fine
    C
    I told you to be balanced
    C/B Am
    I told you to be kind
    C
    In the morning I'll be with you
    C/B Am
    But it will be a different "kind"
    C
    I'll be holding all the tickets
    C/B Am
    And you'll be owning all the fines
    Verse:
    Am C
    Come on skinny love what happened here
    Am C
    Suckle on the hope in lite brassiere
    Am C
    My, my, my, my, my, my, my, my
    D Am
    Sullen load is full; so slow on the split
    C
    Chorus:
    C
    I told you to be patient
    C/B Am
    I told you to be fine
    C
    I told you to be balanced
    C/B Am
    I told you to be kind
    C
    Now all your love is wasted?
    C/B Am
    Then who the hell was I?
    C
    Now I'm breaking at the britches
    C/B Am
    And at the end of all your lines
    Bridge:
    C
    Who will love you?
    C/B Am
    Who will fight?
    C C/B Am
    Who will fall far behind?
    Outro:
    Am C
    Am C
    Am C
    D Am C`
  },
  {
    title: "Boc",
    html: `
    Bocephus King -  Josephina
    
    The entire song is A D, repeated. Use majors, and throw in some arpeggios.
    Lyrics:
    What's the point in saying
    I'll see you 'round some
    Get use to anything
    Get used by anyone
    Got use to having you
    Down here in exile
    You've got a heart of grace
    As big as Buddha's smile, but
    I'm still waiting
    You said you'd come for me
    I'm still waiting
    Just where I said I'd be
    If you don't think I'm a number one contender,
    Hold on
    They gotta name for that
    It's prostitution
    That's just a wedding ring
    It's no solution
    He's just a heart attack
    He's just a bank account
    You gotta save me soul
    And cast the demons out
    If you still want that trash can wedding
    Now pack your bags and you better get ready
    If you still think there's a chance for redemption
    Hold on
    Come back Josephina
    We miss you so much
    Out here they testify
    For drugs or money
    Too many points of view
    They hate your guts, honey
    They'll never leave this place
    Without a funeral
    I don't recall the song
    I know the tune though
    I'm still waiting
    You said you'd come for me
    I'm still waiting
    Just where I said I'd be
    If you don't think I'm a number one contender,
    Hold on
    I gotta leave this place
    They're killing wishes
    Down at the laundromat
    They're getting vicious
    They parked a Cadillac
    Upon my favourite dream, but
    There's still a little pride
    Left here inside of me`
  },
  {
    title: "Bei",
    html: `
    Beirut - Elephant Gun
    
    Capo VI
    Chords:
    Em 022003
    A7 x02223
    D x00232
    G 320003
    Gmaj7 320002
    To play on ukelele tune up a 1/2 step and play the top 4 strings.
    Intro
    Em A7 D G Gmaj7 (x2)
    Verse
    Em A7 D G Gmaj7 Em
    If I was young, I'd flee this town, I'd bury my dreams under - ground
    A7 D G Gmaj7 Em
    As did I, we drink to die, we drink toni - i - ight
    A7 D G Gmaj7 Em
    Far from home, elephant guns, let's take them down one by one
    A7 D G Gmaj7 Em
    We'll lay it down, it's not been found, it's not aro - o - ound
    Chorus
    A7 D G Gmaj7 Em
    Let the seasons begin, it rolls right on
    A7 D G Gmaj7 Em
    Let the seasons begin, take the big king down
    A7 D G Gmaj7 Em
    Let the seasons begin, it rolls right on
    A7 D G Gmaj7 (Em)
    Let the seasons begin, take the big king down
    Bridge
    Em A7 D G Gmaj7 (x4)
    Em A7 D G Gmaj7 (Em)
    Oh oh oh oh oh la la la (x4)
    Em A7 D G Gmaj7 (x8)
    Verse
    Em A7 D G Gmaj7 Em
    And it rips through the silence of our camp at night
    A7 D G Gmaj7 Em
    And it rips through the night, oh la la la
    A7 D G Gmaj7 Em
    And it rips through the silence of our camp at night
    A7 D G Gmaj7 (Em)
    And it rips through the silence, all that is left is all I hide
    Outro
    Em A7 D G Gmaj7 (x4)
    Em`
  },
  {
    title: "Arch",
    html: `
    Architecture in Helsinki / Dr. Dog - Heart it Races
    
    Capo III
    
    Intro:
    G Em x2
    Verse:
    G Em
    And we're slow to acknowledge the knots in the laces
    Heart it races
    G Em
    And we go back to where we moved out to the places
    Heart it races
    G
    I bought it in a can and stirred it with my fingers singing
    Em
    Boom dah dah dah dah dah
    Boom dah dah dah dah
    Threw it out the window
    G
    And lately you been tanned, suspicious for the winter with your
    Em
    Boom dah dah dah dah dah
    Boom dah dah dah dah
    Legs like little splinters
    
    Chorus:
    G Em
    Ahhhhhhhhhhhhhhhh
    G Em
    Ahhhhhhhhhhhhhhhh
    
    Verse:
    G Em
    And we're slow to acknowledge the knots in the laces
    Heart it races
    G Em
    And we go back to where we moved out to the places
    Heart it races
    G
    I sold it to a man and threw him out the window
    He went
    Em
    Boom dah dah dah dah dah
    Boom dah dah dah dah
    Made his wife a widow
    
    Chorus:
    G Em
    Ahhhhhhhhhhhhhhhh
    G Em
    Ahhhhhhhhhhhhhhhh
    
    Outro:
    G
    Boom dah dah dah dah dah
    Boom dah dah dah dah
    Boom dah dah dah dah dah
    Boom dah dah dah dah
    Em
    Boom dah dah dah dah dah
    Boom dah dah dah dah
    Boom dah dah dah dah dah
    Boom dah dah dah dah`
  },
  {
    title: "Ani",
    html: `
    Ani DiFranco and Utah Philips - Korea
    
    This one's really simple.
    G|--4-----2-----------|
    D|----5-----0---2-----|
    A|--------------------|
    E|-----------------0--|
    -or-
    G|--4-----------------|
    D|----5---7-----------|
    A|----------5---7-----|
    E|-----------------0--|`
  },
  {
    title: "Adam",
    html: `
    Adam Cohen - What Other Guy
    
    Cohen switches things up a lot throughout the song.
    Don't always play the high E string, and throw in some hammer-ons to get the sound right. The G/F#, G/B, and A7sus4 chords are transitional, so you can leave them out if you're having trouble.
    
    Capo VI
    
    C: x32033
    G: 320033
    Em: 022033
    D: xx0233
    G/F#: 2x0033
    G/B: x20033
    A7sus4: xx2233
    
    Intro:
    
    C G
    
    C G
    I know what you look like in the morning
    C
    Your kisses are soft and warm
    G
    I can draw you with my eyes closed
    C
    See you with nothing on but the radi-o
    G
    I know how many years of French you took
    C
    Your favourite movies, your favourite books
    G
    I know what really gets you going... glowing
    
    Em D C
    I know where you go with your beautiful friends
    Em D C
    I know what you taste like when the night ends
    
    G Em
    I know the kind of thing that makes you laugh
    C
    The way you tilt your head for a photograph
    G G/F#
    What other guy knows you like that?
    
    G Em
    And I can name the first guy you ever kissed
    C
    I can name the perfume on your wrist
    G G/F#
    What other guy knows you like that?
    
    C G/B
    Oh, Anne
    D A7sus4
    Oh, Anne
    
    C G
    I know what you want by what you're wearing
    C
    The kind of night you're preparing
    G
    I know what your hands do when you're kissing
    C
    Your number one and number two favourite positions
    G
    I know how your skin glistens... listen
    
    Em D C
    I know where you go with your beautiful friends
    Em D C
    I know what you taste like when the night ends
    
    G Em
    I know the kind of thing that makes you laugh
    C
    The way you tilt your head for a photograph
    G G/F#
    What other guy knows you like that?
    
    G Em
    And I can name the first guy you ever kissed
    C
    I can name the perfume on your wrist
    G G/F#
    What other guy knows you like that?
    
    C G/B
    Oh, Anne
    D A7sus4
    Oh, Anne
    C G/B
    Oh, Anne
    D A7sus4
    Oh, Anne
    
    Em D C
    I know where you go with your beautiful friends
    Em D C
    I know what you taste like when the night ends
    
    G Em
    I know the kind of thing that makes you laugh
    C
    The way you tilt your head for a photograph
    G G/F#
    What other guy knows you like that?
    
    G Em
    And I can name the first guy you ever kissed
    C
    I can name the perfume on your wrist
    G G/F#
    What other guy knows you like that?
    
    C G/B
    Oh, Anne
    D A7sus4
    Oh, Anne
    C G/B
    Oh, Anne
    D A7sus4
    Oh, Anne`
  }
]

export default tabs;