import { useParams } from 'react-router-dom';

import { Row, Col, ListGroup } from 'react-bootstrap';
import IcePhysics from './tutorials/IcePhysics';
import Pool from './tutorials/Pool';
import LoginReactNative from './tutorials/LoginReactNative';

function App() {

  const id = useParams();
  console.log(id);
  return (
    <div>
      {
        id.id === "2d-top-down-ice-physics-unity" ?
      <IcePhysics /> 
        : id.id === "2d-pool-unity"
        ? <Pool />
        : id.id === "login-react-native-expo-firebase" ?
        <LoginReactNative />
      : ""
      }
    </div>
  );
}

export default App;
