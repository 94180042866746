import { useState } from 'react';

function App() {

  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
    <header>
      <div style={{flexDirection: "column"}}>
         <h1><a href="/">Matthew Gruman</a></h1>
        <h2>Web and App Developer</h2>
         </div>
         <ul>
         {/* <li className="dropdown dropdown-header" onClick={toggleDropdown}>
            Releases &#9662;
            {isOpen && (
              <ul className="dropdown-list">
                <li><a href="https://backgammonclock.gruman.co">Backgammon Clock</a>
                <p>Cross platform mobile app. 5-star rated</p></li>
                <li><a href="https://backgammonclub.club">Backgammon Club</a>
                <p>Full stack web and mobile app</p></li>
                <li><a href="https://letters.gruman.co">Letters</a>
                <p>Cross platform web and mobile game.</p></li>
              </ul>
            )}
          </li> */}
      {/* <li><a href="/tutorials">Tutorials</a></li> */}
      <li><a href="/projects">Projects</a></li>
      <li><a href="/tutorials">Tutorials</a></li>
      <li><a href="/blog">Blog</a></li>
      <li ><a href="mailto:matthewgruman@gmail.com">Contact</a></li>
    </ul>
    </header>
    <div className="lead-holder">
      <p className="lead">Hello! I'm Matthew Gruman, an award-winning Web and App Developer from Vancouver, BC. Contact me by <a href="mailto:matthewgruman@gmail.com">email</a>, or connect on <a href="https://www.linkedin.com/in/gruman/">LinkedIn</a>.</p>
      <img src={require('../img/profile.jpg')} alt="Matthew Gruman smiling" className="profile" />
      </div>
    </>
  );
}

export default App;
