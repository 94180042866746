export default function App() {

  return(
    <article>
    <h2>Custom Context with React</h2>
    <p className="date">Last updated 20/11/2023</p>
    <p className="lead" style={{color: "#111"}}>This is a tutorial to demonstate React's Custom Context in a straighforward way. It lets you keep a Global State, and share data/functions across pages/components.</p>
    <p>Full code is available on <a href="https://github.com/gruman/custom-context-react">GitHub</a>.</p>
    
<h2>Setting Up</h2>
<p>Create a new React app:</p>
<pre>
  npx create-react-app [app name]
</pre>
<p>Install React Router Dom:</p>
<code>{`yarn add react-router-dom
or
npm install firebase`}
</code>
<p>In the /src/ directory of youe app make a directory named /constants/ and add a file named Navbar.js inside. Next create a folder named /pages/ in the /src/ directory and put two files in there: Home.js and Another.js. Finally add another folder to the /src directory named /state/ and put a file called context.js inside. </p>
<p>The relevant files should look like this:</p>
<code>{`|-components
    |-Navbar.js
|-pages
    |-Home.js
    |-Another.js
|-state
    |-context.js
|-App.js
|-App.css

`}</code>

<h2>App.js</h2>
<p>In App.js we're setting up React Router DOM and wrapping our app in our custom context - {`<ContextProvider>`}. Unless a component is inside tihs set of tags you can not access your context. For example you can't use your context in the App.js file.</p> 
  <code>{`import './App.css';
import Navbar from './components/Navbar'

import { ContextProvider } from './state/context'
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './pages/Home';
import Another from './pages/Another'

function App() {
  return (
    <ContextProvider>
      <BrowserRouter>
        <div className="container">

          <Navbar />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/another" element={<Another />} />
          </Routes>

        </div>
      </BrowserRouter>
    </ContextProvider>
  );
}

export default App;
`}</code>

<h2>context.js</h2>
<p>This is your context. You can see that it uses the same hooks as a regular React component. Any variable or function can be added to the exports.</p>
<code>{`
import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a new context
const Context = createContext();

// Initial data to be provided by the context
const data = {
  name: "Create Context",
  url: "https://gruman.co"
}

// ContextProvider component to provide the context to its children
export const ContextProvider = ({ children }) => {
  // State for the score
  const [score, setScore] = useState(0);

  // State for site data
  const [siteData, setSiteData] = useState(data);

  // Function to update the score
  function updateScore(points) {
    setScore(old => old + points)
  }

  // useEffect example (runs on mount)
  useEffect(() => {
    console.log("You can use useEffect here too.")
  }, [])

  // Provide the context to its children
  return (
    <Context.Provider value={{ score, siteData, updateScore }}>
      {children}
    </Context.Provider>
  );
};

// Custom hook to conveniently use the context
export const useCustomContext = () => useContext(Context);
`}</code>

<h2>Navbar.js</h2>
<p>To keep state across pages you must use React Router DOM's {`<Link>`} tag and not a regular link or the data will disappear.</p>
<code>{
  `import { useCustomContext } from "../state/context"
  import { Link } from 'react-router-dom';
  export default function Navbar() {
    const { score, siteData } = useCustomContext();
  
    return (
      <>
        <header>
          <h1><Link to="/">{siteData.name}</Link></h1>
          <h1><Link to="/another">Another page</Link></h1>
          <p className="points">Score: {score ? score : 0}</p>
        </header>
      </>
    )
  }`}</code>
<h2>Home.js</h2>
<p>This shows how the variables go from state to the page.</p>
<code>{
  `import { useCustomContext } from '../state/context';
  function App() {
  
    const { updateScore, score } = useCustomContext();
  
    return (
      <main>
        <h2>Home page</h2>
        <p>The score is {score ? score : 0}</p>
        <button onClick={() => updateScore(1)}>Add 1 to score</button>
      </main>
    );
  }
  
  export default App;
  `}</code>
  <h2>Another.js</h2>
<p>Another page.</p>
<code>{
  `import { useCustomContext } from '../state/context';

  function App() {
  
    const { score, siteData, updateScore } = useCustomContext();
  
    return (
      <main>
        <h2>Another page from {siteData.url}</h2>
        <p>The score is {score ? score : 0}</p>
        <button onClick={() => updateScore(2)}>Add 2 to score</button>
      </main>
    );
  }
  
  export default App;
  `}</code>
  <h2>App.css</h2>
<code>{
  `@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Lora:ital,wght@0,400;0,700;1,400;1,700&family=Merriweather:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

  body * {
    font-family: "Lora", Georgia, Times, serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #111
  }
  
  .container {
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    max-width: 600px;
    min-height: 100vh;
  }
  
  header {
    padding: 1rem 0;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #111;
    margin-bottom: 1rem;
  }
  
  main {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  
  h1 {
    font-size: 1.2rem;
  }
  
  h2 {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
  
  p {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-bottom: 1rem;
  }
  
  button {
    background-color: #111;
    border: none;
    color: #fff;
    font-family: "Lato";
    padding: 0.5rem;
    text-transform: uppercase;
    border-radius: 0.25rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    display: block;
  }`}</code>
 <p>Full code is available on <a href="https://github.com/gruman/custom-context-react">GitHub</a>.</p>
   
</article>
  )

}