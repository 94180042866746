import { useEffect, useState } from 'react';
import archivedata from '../constants/archive';

function App() {

  const [archive, setArchive] = useState(archivedata.sort((a, b) => a.name.localeCompare(b.name)));
  const [search, setSearch] = useState('');
  const [searchData, setSearchData] = useState(archive);

  useEffect(() => {
    // Filter archivedata based on the search term
    const filteredData = archive.filter(item =>
      item.skills.some(skill => skill.toLowerCase().includes(search.toLowerCase()))
    );

    // Update the searchData state with the filtered data
    setSearchData(filteredData);
  }, [search, archive]);

  const showAll = () => {
    setSearch('');
    setSearchData(archive);
  }

  const monetized = () => {
    const monetizedItems = archive.filter(item => item.monetized === true);
    setSearchData(monetizedItems)
  }
  return (
    <article>
      <h3>Projects</h3>
      <button style={{marginRight: '1rem'}} onClick={showAll}>Show All</button>
      <button onClick={monetized}>Show Monetized</button>
      <ul className="projects-list">
        {
          searchData.map((item, index) => {
            return (
              <li key={index}>
                <h4><a href={"/project/" + item.slug}>{item.name}</a></h4>
                <p>{item.description}</p>
                {
                  item.monetized && 
                  <span className="monetized">Monetized</span>
                }
                <ul className="spiel">
                  {item.spiel.map((item, index) => {
                    return (
                      <li key={index}>{item}</li>
                    )
                  })}
                </ul>
                {
                  item.urls &&
                  <>
                    <h5>URLs:</h5>
                    <ul className="spiel">
                      {item.urls.map((item, index) => {
                        return (
                          <li key={"url" + index}><a href={item.url}>{item.label}</a></li>
                        )
                      })}
                    </ul>
                  </>
                }
                <p className="skilllist">
                  {
                    item.skills.map((i, idx) => {
                      return (
                        <span onClick={() => setSearch(i)}>{i}</span>
                      )
                    })
                  }
                </p>
              </li>
            )
          })
        }
      </ul>
    </article>
  );
}

export default App;
