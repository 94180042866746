import tutorials from '../constants/tutorials';

function App() {

  return (
    <article>
      <h2>Tutorials</h2>
      <ul className="plural-list">
        {
          tutorials.map((item, index) => {
            return(
              <li key={index}><a href={"/" + item.url}>{item.name}</a>
              <p><span className="bold">Technologies: </span>
              {
                item.technologies.map((i, idx) => {
                  return(
                    i + " "
                  )
                })
          }
          </p>
              </li>
            )
          })
        }
      </ul>
    </article>
  );
}

export default App;
