const tutorials = [
  {
    name: "Custom Context with React",
    url: "react-custom-context",
    technologies: [
      "React",
      "Custom Context",
      "React Router DOM"
    ]
  },
  {
    name: "Choose Your Own Adventure",
    url: "choose-your-own-adventure-react-native",
    technologies: [
      "React Native",
      "Global styles",
      "React Navigation",
      "Custom Components"
    ]
  },
  {
    name: "Node with MySQL",
    url: "node-mysql",
    technologies: [
      "Node",
      "Express",
      "MySQL",
      "Sequelize"
    ]
  },
  {
    name: "Login with React Native Expo and Firebase",
    url: "login-react-native-expo-firebase",
    technologies: [
      "React Native",
      "Firebase",
      "Expo"
    ]
  },
  {
    name: "2D Pool in Unity",
    url: "2d-pool-unity",
    technologies: [
      "Unity"
    ]
  },
  {
    name: "2D Ice Physics in Unity",
    url: "2d-top-down-ice-physics-unity",
    technologies: [
      "Unity"
    ]
  }
]

export default tutorials