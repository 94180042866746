const projects = [
  {
    name: "Backgammon Clock",
    slug: "backgammon-clock",
    monetized: false,
    skills: [
      "React Native",
      "Custom Context",
      "CSS",
      "AppStore",
      "GooglePlay"
    ],
    urls: [
      {
        url: "https://apps.apple.com/gb/app/backgammon-clock/id6475617523",
        label: "App Store"
      },
      {
        url: "https://play.google.com/store/apps/details?id=co.gruman.chessclock&hl=en_CA&gl=US",
        label: "Google Play"
      },
      {
        url: "https://backgammonclock.gruman.co",
        label: "Website"
      }
    ],
    spiel: [
      "iOS and Android",
      "5 star rated",
      "Customizable colour",
      "Accessibility-focused"
    ],
    description: "Backgammon Clock is a fully-customizable games clock made for Backgammon. There's delay, dice, and themes.",
    monetized: true,
  }, 
  {
    name: "Backgammon Club",
    slug: "backgammon-club",
    monetized: false,
    skills: [
      "ReactNative",
      "Firebase",
      "Node",
      "Full stack",
      "MaterialUI",
      "CustomContext",
      "CSS",
      "AppStore",
      "GooglePlay"
    ],
    urls: [
      {
        url: "https://apps.apple.com/us/app/backgammon-club/id6475646369?uo=2",
        label: "App Store"
      },
      {
        url: "https://play.google.com/store/apps/details?id=co.gruman.bgclub",
        label: "Google Play"
      },
      {
        url: "https://backgammonclub.club",
        label: "Website"
      }
    ],
    spiel: [
      "Full stack",
      "Share accounts across devices",
      "API and code sharing",
    ],
    description: "Backgammon Club is a way to track your backgammon games. Add players and calculate Elo.",
    monetized: true,
  }, {
    name: "Find the Letter",
    slug: "find-the-letter",
    monetized: true,
    skills: [
      "ReactNative",
      "CustomContext",
      "CSS",
      "AppStore",
      "GooglePlay"
    ],

    urls: [
      {
        url: "https://apps.apple.com/gb/app/find-the-letter/id6475613444",
        label: "App Store"
      },
      {
        url: "https://play.google.com/store/apps/details?id=co.gruman.letters",
        label: "Google Play"
      },
      {
        url: "https://letters.gruman.co",
        label: "Website"
      }
    ],
    spiel: [
      "iOS, Android, and the web",
      "Code sharing",
      "Customizable colour",
      "Accessibility-focused"
    ],
    description: "Find the Letter is a font-searching game. You are presented with 100 letters and have to find the odd one out.",
    monetized: true,
  },
  {
    name: "Tiny Apps",
    slug: "tiny-apps",
    skills: [
      "React",
      "CustomContext",
      "ReactRouterDOM",
      "CSS",
    ],
    urls: [
      {
       url: "https://matthew.gruman.co",
       label: "Website"
      }
    ]
    ,
    spiel: [
      "My secondary portfolio",
      "A growing collection of ~50 apps"
    ],
    description: "Tiny Apps is a collection of small apps."
  },
  {
    name: "Tutorials",
    slug: "tutorials",
    skills: [
      "ReactNative",
      "React",
      "Firebase",
      "CSS",
      "Unity",
      "CustomContext",
      "ReactRouterDOM"
    ],
    urls: [
      {
      url: "https://gruman.co/tutorials",
      label: "Website"
      }
    ]
    ,
    spiel: [
      "Straighforward tutorials",
      "Maximum code comments with minimal text"
    ],
    description: "Tutorials that I write for Unity and React."
  }, {
    name: "Colour Combo",
    slug: "colour-combo",
    monetized: true,
    skills: [
      "ReactNative",
      "REST",
      "Firebase",
      "CSS",
      "Full stack",
      "AppStore",
      "GooglePlay"
    ],
    urls: [
      {
        url: "https://apps.apple.com/gb/app/color-combo/id6476615850",
        label: "App Store"
      },
      {
        url: "https://play.google.com/store/apps/details?id=co.gruman.colorcombo",
        label: "Google Play"
      }
    ],
    spiel: [
      "Cross platform: web and mobile",
      "Battle colour combinations",
      "Uses Tinder-like swipe mechanism"
    ],
    description: "Colour Combo is a swipe game where you work together to find the best colour combination."
  }, {
    name: "Excuse Database",
    slug: "excuse-database",
    monetized: true,
    skills: [
      "ReactNative",
      "React",
      "REST",
      "Full stack",
      "Firebase",
      "CSS",
      "AppStore",
      "GooglePlay"
    ],
    urls: [
      {
        url: "https://apps.apple.com/gb/app/excuse-database/id6476473511",
        label: "App Store"
      },
      {
        url: "https://play.google.com/store/apps/details?id=co.gruman.excusedatabase",
        label: "Google Play"
      },
      {
        url: "https://excusedatabase.com",
        label: "Website"
      }
    ],
    spiel: [
      "Cross platform: web and mobile",
      "1000+ registered users",
      "Shared login across platforms"
    ],
    description: "The Excuse Database, based on Seinfled's Excuse Rolodex, is a collaborative excuse game.",
    monetized: true,
  },
  {
    name: "Backgammon Scorekeeper",
    slug: "backgammon-scorekeeper",
    monetized: true,
    skills: [
      "React Native",
      "Custom Context",
      "Themes",
    ],
    urls: [
      {
        url: "https://apps.apple.com/us/app/backgammon-score-keeper/id6476737735",
        label: "App Store"
      },
      {
        url: "https://play.google.com/store/apps/details?id=co.gruman.scorekeeper",
        label: "Google Play"
      },
    ],
    spiel: [
      "iOS and Android",
      "Customizable colours and fonts",
    ],
    description: "Backgammon Scorekeeper is a scorekeeper design for backgammon. There are different directional modes to allow specators to see.",
    monetized: true,
  },
  {
    name: "REST APIs",
    slug: "apis",
    monetized: false,
    skills: [
      "Nodejs",
      "Firebase",
      "Express",
      "REST",
      "Vercel"
    ],
    urls: [
      {
        url: "https://matthewgruman.com/apis",
        label: "Website"
      }
    ]
    ,
    spiel: [
      "My REST apis for use on other websites",
      "Written in Node with Express",
      "Delivers information in JSON"
    ],
    description: "I really enjoy making APIs."
  },
  {
    name: "Blog",
    slug: "blog",
    monetized: false,
    skills: [
      "React",
      "Wordpress",
      "REST",
      "PHP"
    ],
    url: [
      {
        url: "https://matthewgruman.com/blog",
        label: "Website"
      }
    ]
    ,
    spiel: [
      "Headless Wordpress",
      "The website runs on React but gets its data from Wordress"
    ],
   description: "My blog."
  },
  {
    name: "Lorraine Fortin",
    slug: "lorraine-fortin",
    monetized: true,
    skills: [
      "Wordpress",
      "HTML",
      "CSS",
      "PHP",
      "WooCommerce",
      "MySQL",
      "Nginx"
    ],
    urls: [
      {
        url: "https://lorrainefortin.com",
        label: "Website"
      }
    ]
    ,
    spiel: [
      "Customized Wordpress site",
      "eCommerce using WooCommerce"
    ],
    description: "Wordpress site."
  },
  {
    name: "Visions Ouest Productions",
    slug: "visions-ouest-vancouver",
    monetized: true,
    skills: [
      "Wordpress",
      "HTML",
      "CSS",
      "PHP",
      "MySQL",
      "Nginx"
    ],
    urls: [
      {
        url: "https://rendez-vousvancouver.com",
        label: "Website"
      }
    ]
    ,
    spiel: [
      "Customized Wordpress site",
    ],
    description: "Wordpress site."
  },
   {
    name: "Cow Facts",
    slug: "cow-facts",
    monetized: true,
    skills: [
      "ReactNative",
      "CustomContext",
      "Full stack",
      "CSS",
      "Node",
      "AppStore",
      "GooglePlay"
    ],

    urls: [
      {
        url: "https://play.google.com/store/apps/details?id=co.gruman.cowfacts",
        label: "Google Play"
      }
    ],
    spiel: [
      "iOS and Android",
      "Facts about cows",
      "Email submission via Node and Gmail"
    ],
    description: "Cow fact and a cow game."
  },
  {
    name: "One Nice Thing",
    slug: "one-nice-thing",
    monetized: true,
    skills: [
      "ReactNative",
      "CustomContext",
      "CSS",
      "Full stack",
      "AppStore",
      "GooglePlay",
      "Firebase"
    ],

    urls: [
      {
        url: "https://play.google.com/store/apps/details?id=co.gruman.grudolist",
        label: "Google Play"
      },
    ],
    spiel: [
      "iOS and Android",
      "Write down one nice thing at a time",
      "Searchable archive"
    ],
    description: "One Nice Thing is a diary app."
  },
]

export default projects;