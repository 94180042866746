import React, { useEffect, useState } from 'react';

import apis from '../constants/apis';
import tabsdata from '../constants/tabs';
function App() {

  const [tabs, setTabs] = useState(tabsdata)

  useEffect(() => {
    let sortArray = [...tabs];
    sortArray.sort((a, b) => {
      const titleA = a.title.toLowerCase(); // Convert titles to lowercase for case-insensitive sorting
      const titleB = b.title.toLowerCase();
    
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });

    setTabs(sortArray)
  })

  return (
    <article>
      {
        tabs.map((item, index) => {
          return(
          <pre key={index}>
            {item.html}
          </pre>
          )
        })
      }
    </article>
  );
}

export default App;
