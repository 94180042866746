import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
let url = "http://localhost:3000/"
url = "https://matthewgruman.com/"
function App() {

  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    axios.get('https://wordpress.matthewgruman.com/wp-json/wp/v2/posts/' + id)
      .then(results => {
        setBlog(results.data)
        console.log(results.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])
  return (
    <article>
      {
        blog &&
        <>
        <div>
      <h2><a href={url + "blog/" + blog.id}>{blog.title.rendered}</a></h2>
      </div>
      <div className="blog" dangerouslySetInnerHTML={{ __html: blog.content.rendered }} />
      
      </>
}


    </article>
  );
}

export default App;
