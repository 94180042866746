import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Container } from 'react-bootstrap';

import NavBar from './components/NavBar';
import Home from './pages/Home';
import ERpp from './pages/ERpp';
import BlackJack from './pages/BlackjackPP';
import ClockPP from './pages/ClockPP';
import Project from './pages/Project';
import Projects from './pages/Projects';
import Tutorial from './pages/Tutorial';
import Tutorials from './pages/Tutorials';
import APIs from './pages/APIs';
import Games from './pages/Games';
import Blog from './pages/Blog';
import Tabs from './pages/Tabs';
import BGPP from './pages/BGPP';
import GRPP from './pages/GRPP';
import LoginReactNative from './pages/tutorials/LoginReactNative'
import CustomContext from './pages/tutorials/CustomContext';
import Pool from './pages/tutorials/Pool';
import IcePhysics from './pages/tutorials/IcePhysics';
import NodeMySQL from './pages/tutorials/NodeMySQL';
import Choose from './pages/tutorials/ChooseYourOwnAdventure';
import Blogs from './pages/Blogs';
function App() {
  return (
    <BrowserRouter>
    <div className="message">
      <a href="https://backgammonclub.club/sponsorship">Backgammon Club Sponsorships now available!</a>
    </div>
        <Container>
          <NavBar />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/tutorial/:id" element={<Tutorial />} />
            <Route path="/tutorials" element={<Tutorials />} />
            <Route path="/project/:slug" element={<Project />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/erpp" element={<ERpp />} />
            <Route path="/cpp" element={<ClockPP />} />
            <Route path="/bgpp" element={<BGPP />} />
            <Route path="/bjpp" element={<BlackJack />} />
            <Route path="/grpp" element={<GRPP />} />
            <Route path="/apis" element={<APIs />} />
            <Route path="/games" element={<Games />} />
            <Route path="/tabs" element={<Tabs />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<Blogs />} />
            <Route path="/choose-your-own-adventure-react-native" element={<Choose />} />
            <Route path="/login-react-native-expo-firebase" element={<LoginReactNative />} />
            <Route path="/react-custom-context" element={<CustomContext />} />
            <Route path="/2d-top-down-ice-physics-unity" element={<IcePhysics />} />
            <Route path="/2d-pool-unity" element={<Pool />} />
            <Route path="/node-mysql" element={<NodeMySQL />} />
          </Routes>
          <footer>
            <p className="bold"><a href="mailto:matthewgruman@gmail.com">matthewgruman@gmail.com</a></p>
          </footer>
        </Container>
    </BrowserRouter>
  );
}

export default App;
