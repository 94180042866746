const apps = [
  {
    title: "Moons",
    url: "https://games.gruman.co/moon/"
  },
  {
    title: "Kelsea and Winter",
    url: "https://games.gruman.co/kelsea-and-winter/"
  },
  {
    title: "Muffles",
    url: "https://games.gruman.co/muffles/"
  },
  {
    title: "Rocket Dodger",
    url: "https://games.gruman.co/pinball-arena/"
  },
  {
    title: "Auto Pong",
    url: "https://games.gruman.co/pong/"
  },
  {
    title: "Puddles Loves Muffles",
    url: "https://games.gruman.co/puddles/"
  },
  {
    title: "Puddlr",
    url: "https://games.gruman.co/puddlr/"
  },
  {
    title: "Puzzle 5",
    url: "https://games.gruman.co/puzzle5/"
  },
  {
    title: "Rocket: The Original",
    url: "https://games.gruman.co/rocket/"
  },
  {
    title: "Shape Invaders",
    url: "https://games.gruman.co/shape-invaders/"
  },
];

export default apps;