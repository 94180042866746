import games from '../constants/games';

function App() {

  return (
    <article>
      <h2>Games</h2>
      <ul className="plural-list">
        {
          games.map((item, index) => {
            return(
              <li>
                <a href={item.url}>{item.title}</a>
              </li>
            )
          })
        }

      </ul>
    </article>
  );
}

export default App;
