export default function App() {

  return(
    <article>
    <h2>Choose Your Own Adventure with React Native</h2>
    <p className="date">Last updated 5/2/2024</p>
    <p className="lead" style={{color: "#111"}}>Make a Choose your own adventure app with React Native! This uses the stack navigator and  global styles.</p>
    <p>Full code is available on <a href="https://github.com/gruman/choose-your-own-adventure-react-native">GitHub</a>.</p>
    
<h2>Setting Up</h2>
<p>Please follow these links to setup your project:</p>
<ul>
  <li><a href="https://docs.expo.dev/get-started/create-a-project/">Setup a new Expo project</a></li>
  <li><a href="https://reactnavigation.org/docs/getting-started">Install React Navigation</a></li>
  <li><a href="https://reactnavigation.org/docs/stack-navigator">Install Stack Navigator</a></li>
</ul>
<p>A common error with this setup is not fully installing React Navigation. The documentation is tricky so if you're experiencing difficulty try those steps again.</p>
<h2>File structure</h2>
<code>{`|-components
    |-Button.js
|-contants
    |-alice.js
    |-dark.js
    |-dracula.js
    |-frankenstein.js
    |-hyde.js
    |-styles.js
|-screens
    |-Home.js
    |-Story.js
|-App.js`}</code>
<p>Please download the stories from <a href="https://github.com/gruman/choose-your-own-adventure-react-native">GitHub</a> because they're quite long.</p>
<h2>styles.js</h2>
<p>Instead of adding a StyleSheet to every screen we'll be using an external file and importing that. You can easily change the colors by switching the variables up top.</p> 
  <code>{`import { StyleSheet } from "react-native";

const bg = '#222';
const color = '#fff';
const font = 'Helvetica';

const styles = StyleSheet.create({

  container: {
    flex: 1,
    padding: 20,
    backgroundColor: bg
  },
  footer: {
    justifyContent: 'flex-end',
    flex: 1
  },
  logo: {
    fontFamily: "Lato_700Bold",
    fontSize: 32,
    color: color,
    marginBottom: 10
  },
  logoHolder: {
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: color
  },
  sub: {
    color: color,
    fontSize: 18,
    fontFamily: "Lato_400Regular_Italic"
  },
  title: {
    fontFamily: "Lato_700Bold",
    fontSize: 24,
    color: color,
    marginBottom: 20
  },
  text: {
    fontFamily: font,
    fontSize: 18,
    lineHeight: 24,
    color: color,
    marginBottom: 20
  },
  list: {
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: color
  },
  listText: {
    fontFamily: font,
    fontSize: 24,
    lineHeight: 24,
    color: color,
    marginBottom: 10
  },
  scrollContainer: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },

  textContainer: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  input: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: color,
    padding: 10,
    fontSize: 18,
    fontFamily: font,
    color: color
  }
});

export default styles;`}</code>

<h2>Button.js</h2>
<p>Like the styles we'll be importing our own Button from an external file.</p>
<code>{
  `import React from 'react';
  import { Pressable, Text, StyleSheet } from 'react-native';
  
  // Button component with onPress event, title, and optional color prop
  const Button = ({ marginBottom = 20, onPress, title, backgroundColor = "#FFAE3E", color = "#222" }) => {
    return (
      // Pressable component with onPress event and styling
      <Pressable onPress={onPress} style={[styles.button, { backgroundColor: backgroundColor, marginBottom: marginBottom }]}>
        {/* Text component for the button title with styling */}
        <Text style={[styles.text, { color: color }]}>{title}</Text>
      </Pressable>
    );
  };
  
  // Styles for the Button component
  const styles = StyleSheet.create({
    button: {
      backgroundColor: '#FFAE3E', // Default background color
      paddingVertical: 10,
      paddingHorizontal: 15,
      borderRadius: 10,
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      color: 'white', // Default text color
      fontSize: 21,
      fontWeight: '700',
      textTransform: "uppercase", // Uppercase text
    },
  });
  
  export default Button;
  `}</code>
  <p>It takes a few props, which you will see soon.</p>
  <h2>App.js</h2>
<p>App.js is all about the Navigator. We import all the dependencies then set the Stacks.</p>
<code>{
  `// Import necessary components and screens
  import Home from './screens/Home';
  import Story from './screens/Story';
  import { StatusBar } from 'expo-status-bar';
  import { View, SafeAreaView } from 'react-native'
  // Import navigation components from React Navigation
  import { NavigationContainer } from '@react-navigation/native';
  import { createStackNavigator } from '@react-navigation/stack';
  
  const Stack = createStackNavigator();
  // Main App component
  export default function App() {
  
    return (
      <View style={{ flex: 1, backgroundColor: '#222' }}>
        <SafeAreaView style={{ flex: 1 }}>
          <NavigationContainer>
            <Stack.Navigator
              initialRouteName='Home' // Set the initial route to Home
            >
              <Stack.Screen name="Home" component={Home}
                options={{ headerShown: false }} />
              <Stack.Screen
                name="Story"
                component={Story}
                options={{
                  headerStyle: {
                    backgroundColor: '#222', // Set the background color
                  },
                  headerTintColor: '#fff', // Set the text color
                }}
              />
            </Stack.Navigator>
          </NavigationContainer>
        </SafeAreaView>
        <StatusBar />
      </View>
    );
  }
   `}</code>
<h2>Home.js</h2>
<p>Home is the default screen. First we import our styles and Button component, then the stories (if you don't want them to show up remove them here), then useNavigation() to bring us to the story pages.</p>
<p>Next we create the stories array. We initialize an empty array and then push() the stories into it.</p>
<p>The functions navigate you to the Story screen, sending along the selected story as a parameter.</p>
<p>The rest of the page is a Flatlist using the stories array.</p>
<code>{
  `import React, { useState, useEffect } from 'react';
  import { Text, View, TouchableOpacity, FlatList } from 'react-native';
  import Button from '../components/Button'
  import styles from '../constants/styles';
  
  import dark from '../constants/dark'
  import alice from '../constants/alice'
  import dracula from '../constants/dracula'
  import frankenstein from '../constants/frankenstein'
  import hyde from '../constants/hyde'
  
  import { useNavigation } from '@react-navigation/native'
  
  const Home = () => {
  
    const navigation = useNavigation();
    const [stories, setStories] = useState(null);
  
    useEffect(() => {
      const allStories = [];
      allStories.push(dark);
      allStories.push(alice);
      allStories.push(dracula);
      allStories.push(hyde);
      allStories.push(frankenstein);
      setStories(allStories)
    }, [])
    function getRandomInt(min, max) {
      const minCeiled = Math.ceil(min);
      const maxFloored = Math.floor(max);
      return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
    }
    const getRandomStory = () => {
      navigation.navigate("Story", {
        story: stories[getRandomInt(0, stories.length)]
      })
    }
  
    const Item = ({ item, index }) => (
      <TouchableOpacity onPress={(() => goToStory(index))} style={styles.list}>
        <Text style={styles.listText}>{item.title}</Text>
        <Text style={styles.sub}>{item.original}</Text>
      </TouchableOpacity>
    );
  
    const goToStory = (index) => {
      navigation.navigate("Story", {
        story: stories[index]
      })
    }
    return (
      <View style={styles.container}>
        <View style={styles.logoHolder}>
          <Text style={styles.logo}>React Native</Text>
          <Text style={styles.sub}>Choose your own Adventure</Text>
        </View>
        {
          stories &&
  
          <FlatList
            data={stories}
            renderItem={({ item, index }) => <Item item={item} index={index} />}
            keyExtractor={item => item.title}
          />
        }
        <Button onPress={getRandomStory} title="Random Story" />
      </View>
    );
  };
  
  
  export default Home;
  `}</code>
 <p>Full code is available on <a href="https://github.com/gruman/choose-your-own-adventure-react-native">GitHub</a>.</p>
   
</article>
  )

}