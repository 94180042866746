const projects = [
  {
    name: "Backgammon Club",
    skills: [
      "React Native",
      "Firebase",
      "Unity",
      "React",
      "Node",
      "REST API",
      "Custom Context",
      "CSS",
      "App Store",
      "Google Play"
    ],
    url: 
      "https://backgammonclub.club"
    ,
    spiel: [
      "A collection of 6 IRL backgammon apps",
      "Number #1 and #2 Google results for apps",
      "Monetized with ads and sponsorships",
    ]
  },{
    name: "Letters",
    skills: [
      "React Native",
      "Custom Context",
      "CSS",
      "App Store",
      "Google Play"
    ],
    url: 
      "https://apps.apple.com/gb/app/find-the-letter/id6475613444"
    ,
    spiel: [
      "iOS, Android, and the web",
      "Code sharing",
      "Customizable colour",
      "Accessibility-focued"
    ]
  },
  // {
  //   name: "Blackjack Card Counter",
  //   skills: [
  //     "React Native",
  //     "Redux",
  //     "CSS",
  //     "App Store",
  //     "Google Play"
  //   ],
  //   url:
  //     "https://play.google.com/store/apps/details?id=co.gruman.blackjack&hl=en_CA&gl=US"
  //   ,
  //   spiel: [
  //     "Paid mobile app",
  //     "Learn to count cards by tracking the cards played",
  //     "Customizable with Redux"
  //   ]
  // },
  // {
  //   name: "Tiny Apps",
  //   skills: [
  //     "React",
  //     "Custom Context",
  //     "React Router DOM",
  //     "CSS",
  //   ],
  //   url:
  //     "https://matthew.gruman.co"
  //   ,
  //   spiel: [
  //     "My secondary portfolio",
  //     "A growing collection of ~50 apps"
  //   ]
  // },
  // {
  //   name: "Tutorials",
  //   skills: [
  //     "React Native",
  //     "React",
  //     "Firebase",
  //     "CSS",
  //     "Unity",
  //     "Custom Context",
  //     "React Router DOM"
  //   ],
  //   url:
  //     "https://gruman.co/tutorials"
  //   ,
  //   spiel: [
  //     "Straighforward tutorials",
  //     "Maximum code comments with minimal text"
  //   ]
  // },{
  //   name: "Excuse Rolodex",
  //   skills: [
  //     "React Native",
  //     "React",
  //     "REST api",
  //     "Firebase",
  //     "CSS",
  //     "App Store",
  //     "Google Play"
  //   ],
  //   url:
  //     "https://play.google.com/store/apps/details?id=co.gruman.excuserolodex&hl=en&gl=US&pli=1"
  //   ,
  //   spiel: [
  //     "Cross platform: web and mobile",
  //     "1000+ registered users",
  //     "Shared login across platforms"
  //   ]
  // },
  // {
  //   name: "Grucha Libre",
  //   skills: [
  //     "React",
  //     "Firebase",
  //     "REST api",
  //     "Nodejs",
  //     "Express",
  //     "Axios",
  //     "Vercel",
  //     "CSS"
  //   ],
  //   url:
  //     "https://battlers.gruman.co"
  //   ,
  //   spiel: [
  //     "Automated wrestling",
  //     "Battles happen when users are on the website",
  //     "Public REST api can be called with GET commands"
  //   ]
  // },{
  //   name: "TUTORIAL: Todo List with React, Browser Storage, and Typescript",
  //   skills: [
  //     "React",
  //     "Typescript",
  //     "Bootstrap",
  //     "CSS"
  //   ],
  //   url:
  //     "https://github.com/gruman/Todo-List---Typescript--React--Bootstrap--Local-Storage"
  //   ,
  //   spiel: [
  //     "Tutorial for GitHub",
  //     "Standard Todo List with some extras"
  //   ]
  // },
  {
    name: "APIs",
    skills: [
      "Nodejs",
      "Firebase",
      "Express",
      "REST",
      "Vercel"
    ],
    url:
      "https://matthewgruman.com/apis"
    ,
    spiel: [
      "My REST apis for use on other websites"
    ]
  },
  {
    name: "Blog",
    skills: [
      "React",
      "Wordpress",
      "REST",
      "PHP"
    ],
    url:
      "https://matthewgruman.com/blog"
    ,
    spiel: [
      "Headless Wordpress",
      "The website runs on React but gets its data from Wordress"
    ]
  },
  {
    name: "Lorraine Fortin",
    skills: [
      "Wordpress",
      "HTML",
      "CSS",
      "PHP",
      "WooCommerce"
    ],
    url:
      "https://lorrainefortin.com"
    ,
    spiel: [
      "Customized Wordpress site",
      "eCommerce using WooCommerce"
    ]
  }
]

export default projects;