export default function App() {

  return(
    <article>
    <h2>2D Top Down Ice Physics in Unity</h2>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/CsBrvtu6APw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
   
<p>The goal of this tutorial is to build a 2D Top-Down Ice Physics system similar to <a href="https://en.wikipedia.org/wiki/The_Legend_of_Zelda:_A_Link_to_the_Past">The Legend of Zelda: A Link to the Past</a>. There will be a slippery iced area and a regular ground area. We'll use <a href="https://docs.unity3d.com/ScriptReference/Rigidbody2D.html">Rigidbody2D</a> and <a href="https://docs.unity3d.com/ScriptReference/Collider2D.html">Collider2D</a> alongside <a href="https://docs.unity3d.com/Manual/class-Tilemap.html">Tilemaps</a> to create the project. At the end we'll add a <a href="https://docs.unity3d.com/Manual/class-TrailRenderer.html">Trail Renderer</a> to make the ice physics stand out.</p>



<h2>Importing Assets</h2>



<p>Download the project files from <a href="https://github.com/gruman/2D-Top-Down-Ice-Physics-in-Unity">GitHub</a>. The images are from <a href="https://opengameart.org/content/zelda-like-tilesets-and-sprites">GFX's Zelda-like tilesets and sprites</a>.</p>



<p>We are using two assets: a player image and a simple tilemap.</p>



<p>Copy the "player" and "tilemap" images into your Assets folder. Select the "player" image and make these changes in the Inspector:</p>



<ul><li>Pixels Per Unit: 16</li><li>Filter Mode: Point (no filter). This is the preferred filter for pixel art so they don't blur as they scale up.</li></ul>



<p>Apply changes then select the "tilemap" image and make these changes:</p>



<ul id="block-bce69508-9f2c-4f6b-8b48-db36a4eb0aba"><li>Sprite Mode: Multiple</li><li>Pixels Per Unit: 16</li><li>Filter Mode: Point (no filter)</li></ul>



<p>Apply changes and open the Sprite Editor on the "tilemap" image. Click the Slice dropdown and enter the following settings:</p>



<ul><li>Type: Grid By Cell Count</li><li>Column &amp; Row: C: 4, R: 3</li></ul>


<p>Click Slice, Apply and close the Sprite Editor. Our assets are now sized properly and Unity will know how to extract tiles for the tilemap.</p>



<p>Save the project and we're ready to go!</p>



<h2>Ice Physics Player Controller</h2>



<p>In this part we're building a player controller where it feels like you're on ice. You can use the WASD or arrow keys to control the player.</p>



<p>Drag the "player" image directly onto the scene and it will show up in the Hierarchy. Rename it to "Player" - this is how we'll refer to the Player Object. In the Player's Sprite Renderer, change the Order in Layer to 2. This is to keep the Player on top of the tilemap we are making later.</p>


<p>Add a Rigidbody2D Component and change these settings:</p>



<ul><li>Linear Drag: 0.5</li><li>Gravity Scale: 0</li><li>Constraints: check the Freeze Rotation: Z box to prevent the Player from spinning</li></ul>



<h3>Scripting the Player Controller</h3>



<p>Create a new C# script called "PlayerController" and drag it onto your Player Object. Open up the script and replace it with the following:</p>



<pre>{`using UnityEngine;

public class PlayerController : MonoBehaviour
{
    private Rigidbody2D player;
    public float speed = 6.0f;

    void Start()
    {
        player = GetComponent&lt;Rigidbody2D&gt;();
    }

    void Update()
    {
        Vector2 direction = new Vector2(Input.GetAxisRaw("Horizontal"), Input.GetAxisRaw("Vertical")) * speed;
        player.AddForce(direction, ForceMode2D.Force);
    }
}`}</pre>



<p>Save the file, hit Play, and you have ice physics!</p>




<p>If the Player isn't moving check that the PlayerController script is attached to the Player Object, and that your Rigidbody2D numbers are correct.</p>


<h3>Code Explanation</h3>



<pre class="wp-block-code"><pre>private Rigidbody2D player;
public float speed = 6.0f;</pre></pre>



<p>The first line declares that we are using a Rigidbody2D Component, and we are calling it <em>player</em>. The second line sets the default speed of the player. You can change the speed directly in the code or from the Player's Inspector.</p>



<pre>{`void Start()
{
    player = GetComponent&lt;Rigidbody2D&gt;();
}`}</pre>



<p>In the Start function we get the Rigidbody2D component on the Player Object and assign it to the <em>player</em> variable we made above. We can access the Player Object by using the <em>player.</em> syntax.</p>



<pre>{`void Update()
{
    Vector2 direction = new Vector2(Input.GetAxisRaw("Horizontal"), Input.GetAxisRaw("Vertical")) * speed;
    player.AddForce(direction, ForceMode2D.Force);
}`}</pre>



<p>The Update() function fires once per frame and we're telling it to monitor the Horizontal and Vertical inputs. If a direction is being pushed we apply force to the Player.</p>



<p>Declare a new Vector2 called <em>direction</em>, which uses Input.GetAxisRaw("Horizontal") and Input.GetAxisRaw("Vertical") to monitor the Player's direction from the WASD and arrow keys, then multiplies it by the speed defined above.</p>



<p>We use AddForce() to apply the direction and speed to the player, and tell it to use ForceMode2D.Force mode. This applies force and makes the Player move.</p>



<p>You can adjust the Linear Drag on the Player's Rigidbody2D Component to make the ice more or less slippery. 0f is maximum slipperiness.</p>



<h2>Ice Physics Triggered by a Tilemap Collider</h2>


<h3>Creating the Tilemap</h3>



<p>Create a new 2D Object -&gt; Tilemap in the Hierarchy. Duplicate the default Tilemap, then rename them "Ground" and "Ice," respectively.</p>




<p>Select the Ground layer and open the Tile Palette. If it isn't already open, use Window -&gt; 2D -&gt; Tile Palette. Click the Create New Palette dropdown and add a new palette called Tilemap. Save it in your Assets folder.</p>




<p>Drag the "tilemap" image we sliced up earlier directly onto the Tilemap Tile Palette. It will prompt you for a location. Make a new folder called "Tiles" and click "Choose." The tilemap is done!</p>



<h3>Designing the Scene</h3>



<p>We're going to make a simple scene with ground and ice. Select the Ground tilemap and choose the single grass tile from the Tile Palette. Use the the "Paint a filled box with active brush" and cover the entire scene in green by clicking and dragging.</p>



<p>Next select the Ice layer and change the Tilemap Renderer's Order in Layer to "1." This will keep it above the Ground layer (default value of "0") and below the Player Object. </p>



<p>Add a TilemapCollider2D component to the Ice layer and check the "Is Trigger" box. This means that the ice layer can now trigger events, like changing player physics when switching from ground to ice.</p>


<p>Now we have to assign a Tag to the new TilemapCollider2D so our code knows which collision is which. Create a new Tag called "Ice" by selecting "Add Tag..." on the Ice layer's Inspector. Then select the Ice layer in the Inspector again and assign the new "Ice" tag. It doesn't automatically assign the new Tag.</p>



<p>Done with the tilemap! You can hit Play and everything should work like before, but with a background. If the Player has stopped moving make sure the "Is Trigger" box on the Ice layer is checked. </p>


<h3>Player Collider</h3>



<p>To make the Player respond to the ice we add a Box Collider 2D to the Player Object and keep the default settings. Click the "Edit Collider" button and a green rectangle should appear around the Player in your Scene View. This represents the area that will interact with the ice. If it doesn't appear try zooming in and resizing.</p>



<p>That's it for adding colliders!</p>


<h3>Coding the Colliders</h3>



<p>Open the PlayerController and replace the contents with:</p>



<pre>{`using UnityEngine;

public class PlayerController : MonoBehaviour
{
    private Rigidbody2D player;

    public float speed = 6.0f;
    public bool isIce;

    void Start()
    {
        player = GetComponent&lt;Rigidbody2D&gt;();
    }

    void Update()
    {
        if (isIce)
        {
            Vector2 direction = new Vector2(Input.GetAxisRaw("Horizontal"), Input.GetAxisRaw("Vertical")) * speed;
            player.AddForce(direction, ForceMode2D.Force);
        }
        else
        {
            player.velocity = new Vector2(Input.GetAxisRaw("Horizontal"), Input.GetAxisRaw("Vertical")) * speed;
        }

    }

    private void OnTriggerEnter2D(Collider2D collision)
    {
        if (collision.tag == "Ice")
        {
            isIce = true;
        }
    }

    private void OnTriggerExit2D(Collider2D collision)
    {
        if (collision.tag == "Ice")
        {
            isIce = false;
        }
    }
}`}
</pre>



<p>We're done! Hit Play and your player should now have ice physics on the ice positions and regular physics on the ground.</p>



<p>If things aren't working check that Ice layer is tagged as Ice and the Box Collider 2D is sized properly. </p>


<h3>Code Explanation</h3>



<pre class="wp-block-code"><pre>private bool ice;</pre></pre>



<p>Declare a new boolean called <em>isIce</em>. We will use this to tell the Player Object whether or not they're on ice. It sets itself to FALSE by default, meaning the Player is not on ice.</p>


<pre>{`void Update()
{
    if (isIce)
    {
        Vector2 direction = new Vector2(Input.GetAxisRaw("Horizontal"), Input.GetAxisRaw("Vertical")) * speed;
        player.AddForce(direction, ForceMode2D.Force);
    }
    else
    {
        player.velocity = new Vector2(Input.GetAxisRaw("Horizontal"), Input.GetAxisRaw("Vertical")) * speed;
    }
}`}</pre>



<p>In the Update function we use two different ways to move the Player, depending on whether or not it is on ice. We check if <em>isIce</em> is TRUE, and if it is we use the <strong>Ice Physics Controller</strong> we made earlier. </p>



<p>If <em>isIce</em> is FALSE, we use a different physics controller. It works similar to the <strong>Ice Physics Controller</strong> but is independent to outside forces like drag and inertia. </p>



<pre>{`private void OnTriggerEnter2D(Collider2D collision)
{
    if (collision.tag == "Ice")
    {
        isIce = true;
    }
}`}</pre>



<p>To determine when the Player comes in contact with ice we use the OnTriggerEnter2D function. Any time the Player enters a Trigger the function will fire. This is why we checked the Is Trigger box on the TilemapCollider2D earlier.</p>



<p>When the Player enters a Trigger with the Tag of Ice, we set <em>isIce</em> to TRUE and activate ice physics.</p>



<pre>{`private void OnTriggerExit2D(Collider2D collision)
{
    if (collision.tag == "Ice")
    {
        isIce = false;
    }
}`}</pre>



<p>This is the reverse of the last function. OnTriggerExit2D fires when the Player leaves a Trigger. In this case, if the collider being exited  is tagged "Ice," we set <em>isIce</em> to FALSE and activate regular physics.</p>



<h2>Trail Renderer</h2>



<p>To make the ice effect more visible we will add a Trail Renderer. Add it to your Player Object with Effects -&gt; Trail. Reduce the width in a general downward curve, making a thin Trail that gradually fades away. You can double-click on the line to add nodes and use the handles to make curves.</p>



<p>Set the Time to 2 and the Order in Layer to 1. To preview what it looks like, move the Player around the scene.</p>


<h2>That's it!</h2>

</article>
  )

}