export default function App() {

  return(
    <article>
    <h2>MySQL with Node, Express, and Sequelize</h2>
    <p className="date">Last updated 21/11/2023</p>
    <p className="lead" style={{color: "#111"}}>This is a tutorial to demonstate using MySQL with Node as a backend. There is a React <a href="https://github.com/gruman/node-mysql-companion">Companion App</a> that I suggest you install to test out the results and see how endpoints work.</p>
    <p>Full code is available on <a href="https://github.com/gruman/node-mysql">GitHub</a>.</p>
  <h2>MySQL tools and tips</h2>
  <p>This tutorial doesn't cover how MySQL works. I highly suggest your read a tutorial on MySQL before this since the first install can be complicated.</p>
<h2>Setting Up</h2>

<p>There is quite a lot to install, but if you look at the imports of the code you'll see explanations of what they do.</p>
<p>Type the following into your console:</p>

<code>{`yarn init
or
npm init 

-make sure you put "app.js" as your entry point; the rest won't affect this tutorial.

yarn add express body-parser cors mysql2 sequelize
or
npm install express body-parser cors mysql2 sequelize`}
</code>
<p>In the root directory of your app make an app.js file if it doesn't exist, then add the following directories and put the bracketed files inside:</p>
<ul>
  <li>/constants/ (mysql.js)</li>
  <li>/controllers/ (tutorial.js)</li>
  <li>/models/ (frog.js)</li>
  <li>/routes (tutorial.js)/</li>
</ul>
<p>The relevant files should look like this:</p>
<code>{`|-constants
    |-mysql.js
|-controllers
    |-tutorial.js
|-models
    |-frog.js
|-routes
    |-tutorial.js
|-app.js`}</code>
<h2>MySQL import</h2>
<p>You can use this to populate your database:</p>
<code>{`# ************************************************************
# Sequel Ace SQL dump
# Version 20058
#
# https://sequel-ace.com/
# https://github.com/Sequel-Ace/Sequel-Ace
#
# Host: localhost (MySQL 5.7.39)
# Database: node
# Generation Time: 2023-11-21 20:26:20 +0000
# ************************************************************


/*!40101 SET @OLD_CHARACTER_SET_CLIENT=@@CHARACTER_SET_CLIENT */;
/*!40101 SET @OLD_CHARACTER_SET_RESULTS=@@CHARACTER_SET_RESULTS */;
/*!40101 SET @OLD_COLLATION_CONNECTION=@@COLLATION_CONNECTION */;
SET NAMES utf8mb4;
/*!40014 SET @OLD_FOREIGN_KEY_CHECKS=@@FOREIGN_KEY_CHECKS, FOREIGN_KEY_CHECKS=0 */;
/*!40101 SET @OLD_SQL_MODE='NO_AUTO_VALUE_ON_ZERO', SQL_MODE='NO_AUTO_VALUE_ON_ZERO' */;
/*!40111 SET @OLD_SQL_NOTES=@@SQL_NOTES, SQL_NOTES=0 */;


# Dump of table frogs
# ------------------------------------------------------------

DROP TABLE IF EXISTS "frogs";

CREATE TABLE "frogs" (
  "id" int(11) unsigned NOT NULL AUTO_INCREMENT,
  "name" varchar(100) DEFAULT NULL,
  "createdAt" date DEFAULT NULL,
  "updatedAt" date DEFAULT NULL,
  PRIMARY KEY ("id")
) ENGINE=InnoDB DEFAULT CHARSET=utf8;

LOCK TABLES "frogs" WRITE;
/*!40000 ALTER TABLE "frogs" DISABLE KEYS */;

INSERT INTO "frogs" ("id", "name", "createdAt", "updatedAt")
VALUES
	(14,'New record','2023-11-21','2023-11-21'),
	(15,'New record','2023-11-21','2023-11-21');

/*!40000 ALTER TABLE "frogs" ENABLE KEYS */;
UNLOCK TABLES;



/*!40111 SET SQL_NOTES=@OLD_SQL_NOTES */;
/*!40101 SET SQL_MODE=@OLD_SQL_MODE */;
/*!40014 SET FOREIGN_KEY_CHECKS=@OLD_FOREIGN_KEY_CHECKS */;
/*!40101 SET CHARACTER_SET_CLIENT=@OLD_CHARACTER_SET_CLIENT */;
/*!40101 SET CHARACTER_SET_RESULTS=@OLD_CHARACTER_SET_RESULTS */;
/*!40101 SET COLLATION_CONNECTION=@OLD_COLLATION_CONNECTION */;

`}</code>
<h2>mysql.js</h2>
<p>This is just a config file for MySQL. Replace the values with your own.</p> 
  <code>{`// Import Sequelize library for database connection
const Sequelize = require('sequelize');

// Create a new Sequelize instance for connecting to the MySQL database
const sequelize = new Sequelize("node", "root", "root", {
  dialect: 'mysql',  // Specify the database dialect (in this case, MySQL)
  logging: true,     // Enable logging of SQL queries (for debugging)
  host: "localhost",  // Database host
  port: 8889          // Database port
});

// Export the configured Sequelize instance to be used in other parts of the application
module.exports = sequelize;`}</code>

<h2>app.js</h2>
<p>app.js is your entry point. All the site configuation happens here.</p>
<code>{`// Import the express library
const express = require('express');
// Create an instance of the express application
const app = express();
// Import the body-parser library for handling HTTP request bodies
var bodyParser = require('body-parser');
// Define the port for the server to listen on
const port = 8082;
// Import the cors library for handling Cross-Origin Resource Sharing
const cors = require('cors');

// Enable CORS for all routes
app.use(cors());
// Configure body-parser to handle URL-encoded and JSON request bodies
app.use(bodyParser.urlencoded({ extended: false }));
app.use(bodyParser.json());

// Set up middleware to handle CORS headers
app.use((req, res, next) => {
  res.setHeader('Access-Control-Allow-Origin', '*');
  res.setHeader('Access-Control-Allow-Methods', 'OPTIONS, GET, POST, PUT, PATCH, DELETE');
  res.setHeader('Access-Control-Allow-Headers', 'Content-Type, Authorization');
  next();
});

// Import tutorial routes from the 'tutorial' module
const tutorialRoutes = require('./routes/tutorial');
// Use the tutorial routes for requests to the root path
app.use('/', tutorialRoutes);

// Start the server and listen on the specified port
app.listen(port, () => {
  console.log("Example app listening on port " + port);
});
`}</code>

<h2>tutorial.js (controller)</h2>
<p>The Controller holds all of your actions: get, add, delete, etc.</p>
<code>{
  `// Import the Frog model for database operations
  const Frog = require('../models/frog');
  // Import the sequelize instance connected to the MySQL database
  const sequelize = require('../constants/mysql');
  
  // Get all items (frogs) from the database
  exports.getItems = (req, res, next) => {
    Frog.findAll()
      .then(results => {
        // Send the retrieved items as a response
        res.send(results);
        console.log(results);
      })
      .catch(err => {
        // Log any errors that occur during the database operation
        console.log(err);
      });
  };
  
  // Add a new item (frog) to the database
  exports.addItem = (req, res, next) => {
    // Extract the name from the request body
    const name = req.body.name;
    // Create a new Frog instance with the provided name
    Frog.create({
      name: name,
    })
      .then(results => {
        // Log the result of the database operation
        console.log(results);
      })
      .catch(err => {
        // Log any errors that occur during the database operation
        console.log(err);
      });
  };
  
  // Delete an item (frog) from the database based on the provided ID
  exports.deleteItem = (req, res, next) => {
    // Extract the ID from the request body
    const id = req.body.id;
    // Delete the Frog with the specified ID
    Frog.destroy({
      where: {
        id: id
      },
    })
      .then(results => {
        // Log the result of the database operation
        console.log(results);
      })
      .catch(err => {
        // Log any errors that occur during the database operation
        console.log(err);
      });
  };
  
  // Reset all values in the database (drop and recreate tables)
  exports.resetValues = async (req, res, next) => {
    try {
      // Reset values by synchronizing the Sequelize models with the database (force option drops tables)
      await sequelize.sync({ force: true });
      console.log('All tables dropped successfully.');
  
      // Send a success message as a response
      res.status(200).json({ message: 'Reset complete.' });
    } catch (error) {
      // Log any errors that occur during the database operation
      console.error('Error:', error);
      // Send an internal server error response
      res.status(500).json({ error: 'Internal server error' });
    }
  };
  `}</code>
<h2>frog.js</h2>
<p>frog.js is where your database is definied. You'll see with the resentValues() function that it uses this to clear the database.</p>
<code>{
  `// Import Sequelize library for database modeling
  const Sequelize = require('sequelize');
  
  // Import the sequelize instance connected to the MySQL database
  const sequelize = require('../constants/mysql');
  
  // Define a Sequelize model for the 'frog' table
  const Frog = sequelize.define('frog', {
    // Define 'id' as an integer column with auto-increment, not null, and primary key constraints
    id: {
      type: Sequelize.INTEGER,
      autoIncrement: true,
      allowNull: false,
      primaryKey: true
    },
    // Define 'name' as a string column that cannot be null
    name: {
      type: Sequelize.STRING,
      allowNull: false
    }
  });
  
  // Export the Frog model to be used in other parts of the application
  module.exports = Frog;
  `}</code>
  <h2>tutoial.js (route)</h2>
<p>This is the second part of the app's journey. When a certain path is called in runs through here and finds the appropriate controller.</p>
<code>{
  `// Import the express library
  const express = require('express');
  // Import the tutorialController module for handling tutorial-related logic
  const tutorialController = require('../controllers/tutorial');
  
  // Create a new instance of the express router
  const router = express.Router();
  
  // Define routes for handling different tutorial-related actions
  
  // Route for getting items
  router.get('/getItems', tutorialController.getItems);
  
  // Route for adding an item
  router.post('/addItem', tutorialController.addItem);
  
  // Route for deleting an item
  router.post('/deleteItem', tutorialController.deleteItem);
  
  // Route for resetting values
  router.get('/resetValues', tutorialController.resetValues);
  
  // Export the router so that it can be used in other parts of the application
  module.exports = router;
  `}</code>
  <h2>Suggested Tools</h2>
  <ul>
    <li><a href="https://nodemon.io">Nodemon</a> for running node apps.</li>
    <li><a href="https://www.mamp.info">MAMP</a> for local MySQL.</li>
    <li><a href="https://sequel-ace.com">Sequel Ace</a> for visualizing MySQL.</li>
  </ul>
 <p>Full code is available on <a href="https://github.com/gruman/node-mysql">GitHub</a>.</p>
   
</article>
  )

}